/* eslint-disable */
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Ref, Watch } from "vue-property-decorator";
import CustomStore from "devextreme/data/custom_store";
import { LoadResultObject } from "devextreme/common/data/custom-store";

import DxfDataGrid from "../../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText, translateExists } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";

import deviceService from "@/api/device";
import { ExportEvent, ExportType } from "@/domain/Export/ExportHeaderInterface";
import ExportDevices from "@/ui/Export/ExportDevices";

export interface TableFilterTab {
  family: string;
  subtype: string;
}

export interface TableFilter {
  idTab: TableFilterTab | string;
  createdAtValueEnd?: string;
  createdAtValueStart?: string;
}

export interface TablePagination {
  rowsPerPage: number;
  page: number;
  descending: boolean;
}

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class DerviceTable extends Vue {
  /** Filtro que viene de pantalla. */
  @Prop() filterOptions!: any;
  /** Palabra a buscar. */
  @Prop() search!: string | null;
  /** Identificador del tab al que pertenece esta tabla. */
  @Prop() tabIndex!: number;

  @Prop() menuOptions!: any;

  @Prop() exportName!: string;

  /** Referencia a la tabla. */
  @Ref("refDxfDataGrid") tableDevExtreme!: DxfDataGrid;

  public title = "";

  //TODO: Hay límite para exportar

  /** Listado de tabs. */
  //tabsList: any;

  /** Filtro que se le va a mandar al endpoint. */
  filter: TableFilter = {
    idTab: { family: "PANEL", subtype: "4G" },
  };

  /** Conexión para obtener los datos de la tabla. */
  dataStore: CustomStore;

  /** Columnas de la tabla. */
  get columns(): Column[] {
    // Se obtiene como un getter para las traducciones.
    return this.getColumns();
  }

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.dataStore = this.getNewCustomStore();
  }

  created() {
    this.title = this.exportName;
  }

  /** Cuando cambia el fitro se recarga la tabla. */
  @Watch("filterOptions", { immediate: true, deep: true })
  onChangeFilterOptions() {
    if (this.tabIndex === this.getCurrentTab()) {
      this.dataStore = this.getNewCustomStore();
    }
  }

  /** Cuando cambia la palabra a buscar se recarga la tabla. */
  @Watch("search", { immediate: true, deep: true })
  onChangeSearch() {
    if (this.tabIndex === this.getCurrentTab()) {
      this.dataStore = this.getNewCustomStore();
    }
  }

  /** Obtiene el objeto store y hace un load de los datos. */
  getNewCustomStore(): CustomStore {
    const loadDevicesRecursively = async (pagination: TablePagination) => {
      try {
        const sort = "serialNumber";
        const res = await deviceService.getDevices(
          this.filterOptions,
          pagination as any,
          this.search as any,
          sort as any
        );

        loadedDevices = loadedDevices.concat(res.data.content);

        if (loadedDevices.length < res.data.totalElements) {
          const nextPage = pagination.page + 1;
          const nextPagination: TablePagination = {
            ...pagination,
            page: nextPage,
          };
          await loadDevicesRecursively(nextPagination);
        } else {
          allDevicesLoaded = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    let loadedDevices: any[] = [];
    let allDevicesLoaded = false;

    return new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        if (loadOptions.isLoadingAll === true) {
          loadedDevices = [];
          const pagination: TablePagination = {
            rowsPerPage: 2000,
            page: 1,
            descending: false,
          };

          if (!allDevicesLoaded) {
            await loadDevicesRecursively(pagination);
          }
        } else {
          const pagination: TablePagination = {
            rowsPerPage: loadOptions.take ?? 10,
            page: Math.ceil(
              ((loadOptions.skip ?? 0) + 1) / (loadOptions.take ?? 0)
            ),
            descending: loadOptions.sort?.[0]?.desc ?? false,
          };

          const sort = this.getSort(loadOptions, "serialNumber");
          try {
            const res = await deviceService.getDevices(
              this.filterOptions,
              pagination as any,
              this.search as any,
              sort
            );

            loadedDevices = res.data.content;

            loadedDevices.map((device) => {
              device.to = {
                name: "DeviceDetails",
                params: {
                  deviceId: device.serialNumber,
                },
              };
            });

            const result: LoadResultObject = {
              data: loadedDevices,
              totalCount: res.data.totalElements,
            };

            return result;
          } catch (err) {
            console.error(err);
          }
        }

        const data = allDevicesLoaded ? loadedDevices : [];

        const result: LoadResultObject = {
          data: data,
          totalCount: allDevicesLoaded ? loadedDevices.length : 0,
        };

        allDevicesLoaded = false;
        return result;
      },
    });
  }

  getSort(loadOptions: any, defaultSort: string) {
    let sort = loadOptions.sort?.[0]?.selector ?? defaultSort;
    if (typeof sort === "function") {
      sort = sort();
    }
    return sort;
  }

  getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "serialNumber",
        caption: translateText("device.serialNumber"),
        cellTemplate: this.$ability.can("details", "devices")
          ? "linkDetail"
          : "notLinkDetail",
      },
      {
        dataField: "status",
        caption: translateText("device.status"),
        cellTemplate: "cellStatusChipDervice",
        alignment: "left",
      },
      {
        dataField: "typeAndSubtype",
        caption: translateText("device.type"),
      },
      {
        dataField: "manufacturingDate",
        caption: translateText("device.productionDate"),
        cellTemplate: "dateFormatUnix",
        alignment: "left",
      },
      {
        dataField: "lastActivityDate",
        caption: translateText("device.lastActivityDate"),
        cellTemplate: "dateFormatUnix",
        alignment: "left",
      },
      {
        dataField: "lastTelemetryDate",
        caption: translateText("device.lastTelemetryDate"),
        cellTemplate: "dateFormatUnix",
        alignment: "left",
      },
      {
        dataField: "versionHW",
        caption: translateText("general.hardware"),
      },
      {
        dataField: "version",
        caption: translateText("general.version"),
      },
      {
        dataField: "ol",
        caption: translateText("device.ol"),
      },
    ];

    const idTab = this.getCurrentTab();
    if (idTab === 6) {
      columns.splice(2, 0, {
        dataField: "family",
        caption: translateText("device.family"),
        visible: true,
        calculateDisplayValue: (rowData: any) => {
          return translateText("device.deviceDetails." + rowData.family);
        },
        calculateSortValue: () => "family",
      });
    } else if (idTab === 4 || idTab === 5) {
      columns.push({
        dataField: "installationId",
        caption: translateText("deviceDetails.installation"),
        cellTemplate: "linkInstallationDetail",
      });
      columns.push({
        dataField: "wirelessSignal",
        caption: translateText("device.properties.signal_wifi"),
      });
      columns.push({
        dataField: "iccid",
        caption: translateText("deviceDetails.iccid"),
      });
      columns.push({
        dataField: "imei",
        caption: translateText("deviceDetails.imei"),
      });
      columns.push({
        dataField: "simStatus",
        caption: translateText("deviceDetails.simStatus"),
        calculateDisplayValue: (rowData: any) => {
          const status = rowData.simStatus;
          if (translateExists("deviceDetails.status." + status)) {
            return translateText("deviceDetails.status." + status);
          }
          return "";
        },
        calculateSortValue: () => "simStatus",
      });
      columns.push({
        dataField: "simDateUpdated",
        caption: translateText("deviceDetails.simDate"),
        cellTemplate: "dateFormat",
        alignment: "left",
      });
    }

    columns.push({
      dataField: "zone",
      caption: translateText("geo.zone"),
    });
    columns.push({
      dataField: "subzone",
      caption: translateText("geo.subzone"),
    });
    columns.push({
      dataField: "area",
      caption: translateText("geo.area"),
    });

    columns.push({
      cellTemplate: "menu",
      width: 60,
      allowExporting: false,
    });

    return columns;
  }

  /** Obtiene el identificador del tab del filtro. */
  getCurrentTab(): number {
    const idTab: string = sessionStorage.getItem("Devices.tab") ?? "0";
    return parseInt(idTab);
  }

  get showExport() {
    return this.$ability.can("export", "installations");
  }

  private async recursivelyLoadData(
    data: any[],
    pagination: TablePagination,
    sortField: string
  ) {
    try {
      const sort = sortField;
      const res = await deviceService.getDevices(
        this.filterOptions,
        pagination as any,
        this.search as any,
        sort as any
      );

      data.push(...res.data.content);

      if (data.length < res.data.totalElements) {
        pagination.page = pagination.page + 1;
        await this.recursivelyLoadData(data, pagination, sortField);
      }
    } catch (err) {
      console.error(err);
    }
  }

  public async onExport(event: ExportEvent) {
    const data = [];
    const pagination: TablePagination = {
      rowsPerPage: 2000,
      page: 1,
      descending: (event.sort?.order || -1) === -1,
    };
    await this.recursivelyLoadData(
      data,
      pagination,
      event.sort?.field || "serialNumber"
    );
    const idTab = this.getCurrentTab();
    const exportTable = new ExportDevices(this.title, idTab);
    exportTable.addData(data);
    exportTable.download(event.type);
  }
}
