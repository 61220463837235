import { Vue, Component } from "vue-property-decorator";

import { PANEL_FAMILY_CODE } from "@/constants";
import { EDIBOX_FAMILY_CODE } from "@/constants";

import firmwareService from "@/api/firmware";

import AppListMenu from "@/components/AppListMenu.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";

import DialogOk from "@/ui/components/DialogOk/DialogOk.vue";

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
} from "devextreme-vue/data-grid";
import { DxCheckBox } from "devextreme-vue";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import {
  FirmwareHWCompatibleRes,
  FirmwareVersionV2,
  HWList,
} from "@/interfaces/firmwares";

import { FirmwareController } from "@/domain/controllers/FirmwareController";
import DialogUploadFw from "./DialogUploadFw/DialogUploadFw.vue";
import {
  FirmwareModel,
  FirmwareModelInterface,
} from "@/ui/models/FirmwareModel";
import FirmwareFilter from "./FirmwareFilter/FirmwareFilter.vue";
import FirmwareFilterEntity from "./FirmwareFilter/FirmwareFilterEntity";
import FirmwareInterface from "@/domain/Firmware/FirmwareInterface";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import FirmwareTable from "./FirmwareTable/FirmwareTable.vue";

@Component({
  components: {
    AppListMenu,
    AppBreadcrumbs,
    AppKpiChipInfo,
    FilterTableHeader,
    DxDataGrid,
    DxCheckBox,
    DxEditing,
    DxColumn,
    DxPaging,
    DialogUploadFw,
    DialogOk,
    FirmwareFilter,
    FirmwareTable,
  },
})
export default class Firmware extends Vue {
  public filter = FirmwareFilterEntity.getFilter();
  public dataFiltered: FirmwareInterface[] = [];
  private ctrlFirmware = new FirmwareController();

  public PANEL_FAMILY_CODE = PANEL_FAMILY_CODE;
  public EDIBOX_FAMILY_CODE = EDIBOX_FAMILY_CODE;

  private deviceFamily: string | null = null;
  private deviceType: string | null = null;
  private deviceSubtype: string | null = null;

  public editDialog = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public currentFirmware: any = null;
  public changelogEdit = "";
  public detailsEdit = "";
  public automaticUpdateEdit = false;
  public validEditForm = true;
  private confirmRemoveFirmwareId = null;

  $refs!: {
    editForm: HTMLFormElement;
  };

  public selectAll = false;

  public hwListDetailEdit: HWList[] = [];

  public get menuOptions() {
    return [
      {
        icon: "edit",
        text: this.$t("general.edit"),
        action: (item) => this.prepareToEdit(item),
        allowed: this.$ability.can("edit", "firmwares"),
      },
      {
        icon: "cloud_download",
        text: this.$t("general.download"),
        action: (item: FirmwareInterface) => {
          const link = document.createElement("a");
          link.href = item.uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        allowed: this.$ability.can("download", "firmwares"),
      },
      {
        icon: "delete",
        text: this.$t("general.delete"),
        action: (item) => this.setConfirmRemoveFirmwareId(item.id),
        allowed: this.$ability.can("delete", "firmwares"),
      },
    ];
  }

  public get firmwaresInfo() {
    const listInfo = this.$store.getters["firmwares/firmwaresInfo"];
    return listInfo;
  }

  public get numberOfDeviceTypes() {
    return this.$store.getters["firmwares/numberOfDeviceTypes"];
  }

  public get showConfirmRemoveFirmware() {
    return !!this.confirmRemoveFirmwareId;
  }

  public set showConfirmRemoveFirmware(localValue) {
    if (!localValue) this.confirmRemoveFirmwareId = null;
  }

  public get changelogRules() {
    return [(v) => !v || v.length <= 50 || this.$t("firmware.changelogmax")];
  }

  public get detailsRules() {
    return [(v) => !v || v.length <= 255 || this.$t("firmware.detailsmax")];
  }

  /**
   * Comprueba si se ha seleccionado algún HW.
   * En el formulario de edición.
   */
  private get isSelectedAnyHWDialogEdit(): boolean {
    return this.hwListDetailEdit.some((i: HWList) => {
      return i.selectHW === true;
    });
  }

  /**
   * Comprueba si se ha seleccionado un HW.
   * En el formulario de edición.
   */
  private get isSelectedOneHWDialogEdit(): boolean {
    return (
      this.hwListDetailEdit.filter((i: HWList) => {
        return i.selectHW === true;
      }).length === 1
    );
  }

  mounted() {
    this.$store.dispatch("firmwares/getNumberOfDeviceTypes");
    this.loadData();
  }

  private async loadData() {
    await this.$store.dispatch("firmwares/getFirmwaresList");
    const data = await this.$store.getters["firmwares/firmwaresInfo"];
    this.dataFiltered = this.filter.filter(data.lista);
  }

  private setConfirmRemoveFirmwareId(v) {
    this.confirmRemoveFirmwareId = v;
  }

  public onFilter() {
    this.dataFiltered = this.filter.filter(this.firmwaresInfo.lista);
  }

  showConfirmCheckboxAutUpd = false;
  showConfirmCheckboxLatest = false;
  handleClickCheckBox(automatic: boolean, hw: string, checkValue: boolean) {
    if (checkValue) {
      const family = this.deviceFamily || this.currentFirmware.family;
      const type = this.deviceType || this.currentFirmware?.type;
      const subtype = this.deviceSubtype || this.currentFirmware?.subtype;

      if (automatic) {
        this.ctrlFirmware.existAutomaticUpdate(family, type, subtype, hw).then(
          (res: boolean) => {
            this.showConfirmCheckboxAutUpd = res;
          },
          (err) => {
            console.error(err);
          }
        );
      } else {
        this.ctrlFirmware.existLatest(family, type, subtype, hw).then(
          (res: boolean) => {
            this.showConfirmCheckboxLatest = res;
          },
          (err) => {
            console.error(err);
          }
        );
      }
    }
  }

  public toggleSelectAll(detalleData) {
    detalleData.forEach((item) => {
      item.selectHW = this.selectAll;
    });
  }

  /** Acción que se ejecuta cuando le das al botón de editar de la fila. */
  private async prepareToEdit(firmware) {
    this.editDialog = true;
    this.currentFirmware = firmware;
    this.changelogEdit = this.currentFirmware.changelog;
    this.detailsEdit = this.currentFirmware.details;

    const hwlisttemp1 = this.currentFirmware.hwList.map(
      ({ hw, automaticUpdate, latest }) => ({
        selectHW: true,
        hw,
        automaticUpdate,
        latest,
      })
    );

    const fwHWComp: FirmwareHWCompatibleRes =
      await this.ctrlFirmware.getFwHwCompatible(
        this.currentFirmware.family,
        this.currentFirmware.type,
        this.currentFirmware.subtype
      );

    const hwlisttemp2 = fwHWComp.hwList.map((item) => ({
      selectHW: false,
      hw: item,
      automaticUpdate: false,
      latest: false,
    }));

    this.hwListDetailEdit = this.unifyHwLists(hwlisttemp1, hwlisttemp2);
  }

  private unifyHwLists(list1: HWList[], list2: HWList[]): HWList[] {
    const uniqueMap = new Map<string, HWList>();

    [...list1, ...list2].forEach((item: HWList) => {
      if (!uniqueMap.has(item.hw)) {
        uniqueMap.set(item.hw, item);
      }
    });

    return [...uniqueMap.values()];
  }

  /**
   * Comprueba si el firmware es de los que tienen el nuevo formato.
   * @param firmware Versión del firmware.
   */
  private isCompatibleFirmware(firmware: FirmwareVersionV2): boolean {
    return parseInt(firmware.fwMajor) !== 0;
  }

  /**
   * Guarda la edición de un firmware.
   * Si el firmware es compatible, es necesario
   * que haya seleccionado, al menos, un hardware.
   * Si el firmware no es compatible, es necesario
   * que seleccione, solo, un hardware.
   */
  public async editFirmwareProperties() {
    const isCompFirm = this.isCompatibleFirmware(
      this.currentFirmware.firmwareVersionV2
    );
    if (
      (isCompFirm && this.isSelectedAnyHWDialogEdit) ||
      (!isCompFirm && this.isSelectedOneHWDialogEdit)
    ) {
      this.editDialog = false;

      try {
        await firmwareService.setStatus(this.currentFirmware.id, "INPROGRESS");

        this.currentFirmware.changelog = this.changelogEdit;
        this.currentFirmware.details = this.detailsEdit;
        this.currentFirmware.hwList = this.hwListDetailEdit
          .filter((element: HWList) => element.selectHW)
          .map(({ hw, automaticUpdate, latest }) => ({
            hw,
            automaticUpdate,
            latest,
          }));

        this.currentFirmware.status = "INPROGRESS";
        await firmwareService.uploadFirmwareDetails(
          this.currentFirmware.id,
          this.currentFirmware
        );
        await this.$store.dispatch("firmwares/getFirmwaresList");
      } catch (err) {
        await firmwareService.setStatus(this.currentFirmware.id, "COMPLETED");
        this.$store.dispatch("errorHandler", {
          error: err,
          forbiddenText: ["firmware.error.completedStatus"],
          notFoundText: ["firmware.error.notFound"],
          genericErrorText: ["firmware.error.registrationFail"],
        });
      }
    } else {
      this.$store.dispatch("errorHandlerCustom", {
        text: isCompFirm ? "firmware.needAnyHW" : "firmware.needOneHW",
      });
    }
  }

  public cancelEdit() {
    this.editDialog = false;
    this.$refs.editForm.resetValidation();
    this.currentFirmware = null;
    this.loadData();
  }

  public removeFirmware() {
    const id = this.confirmRemoveFirmwareId;

    this.showConfirmRemoveFirmware = false;

    firmwareService
      .removeFirmware(id)
      .then(() => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("firmware.info.deletedJob"),
        });
        this.loadData();
      })
      .catch((err) => {
        this.$store.dispatch("errorHandler", {
          error: err,
          conflictText: ["firmware.error.haveJobs"],
          genericErrorText: ["firmware.error.deleteFail"],
        });
      });
  }

  /** Desabilita la casilla de actualización automática. */
  public isDisabledAutUpdate(rowData: HWList, curFw: FirmwareModelInterface) {
    const firmwareEntity = new FirmwareModel(curFw);
    return !(rowData.selectHW && firmwareEntity.canAutoUpdate());
  }

  /** Cuando se inserta un registro nuevo se recarga la tabla. */
  public onInsertFirmware() {
    this.loadData();
  }

  public onSearch() {
    this.dataFiltered = this.filter.filter(this.firmwaresInfo.lista);
  }
}
