import FirmwareInterface from "@/domain/Firmware/FirmwareInterface";
import {
  DeviceFamilyInterface,
  DeviceTypeInterface,
} from "@/interfaces/devices";
import moment from "moment";

export default class FirmwareFilterEntity {
  public search: string | undefined | null;
  /** Firmware ID */
  public fwid: string | undefined | null;
  /** Familia **/
  public devicefamily: DeviceFamilyInterface | undefined | null;
  /** Tipo de dispositivo **/
  public devicetype: DeviceTypeInterface | undefined | null;
  /** Versión SW **/
  public versionSW: string | undefined | null;
  /** Fecha de alta YYYY-MM-DD **/
  public dateValueStart: string | undefined | null;
  public dateValueEnd: string | undefined | null;
  /** ChangeLog **/
  public changelog: string | undefined | null;
  /** Actualización automática **/
  public automaticUpdateFilter: boolean | undefined | null;
  /** Última versión **/
  public latestFilter: boolean | undefined | null;

  public count: number;
  private storeSave: boolean;

  private constructor() {
    this.count = 0;
    this.storeSave = true;
  }

  public clear() {
    this.fwid = undefined;
    this.devicefamily = undefined;
    this.devicetype = undefined;
    this.versionSW = undefined;
    this.dateValueStart = undefined;
    this.dateValueEnd = undefined;
    this.changelog = undefined;
    this.automaticUpdateFilter = undefined;
    this.latestFilter = undefined;
  }

  /**
   * Limpia los parámetros nulos o vacíos y los guarda en el
   * store del navegador. Si storeSave es falso, no se guardan
   * pero sí se limpian.
   */
  private save() {
    this.clearParams();
    if (this.storeSave) {
      const dataToSave = {
        search: this.search,
        fwid: this.fwid,
        devicefamily: this.devicefamily,
        devicetype: this.devicetype,
        versionSW: this.versionSW,
        dateValueStart: this.dateValueStart,
        dateValueEnd: this.dateValueEnd,
        changelog: this.changelog,
        automaticUpdateFilter: this.automaticUpdateFilter,
        latestFilter: this.latestFilter,
      };
      localStorage.setItem(
        "Firmware.filterOptions",
        JSON.stringify(dataToSave)
      );
    }
  }

  public static getFilter(storeSave = true): FirmwareFilterEntity {
    const filter = new FirmwareFilterEntity();
    filter.storeSave = storeSave;
    if (filter.storeSave) {
      const filterStorage = localStorage.getItem("Firmware.filterOptions");
      if (filterStorage) {
        const tfi: FirmwareFilterEntity = JSON.parse(filterStorage);
        filter.search = tfi.search;
        filter.fwid = tfi.fwid;
        filter.devicefamily = tfi.devicefamily;
        filter.devicetype = tfi.devicetype;
        filter.versionSW = tfi.versionSW;
        filter.dateValueStart = tfi.dateValueStart;
        filter.dateValueEnd = tfi.dateValueEnd;
        filter.changelog = tfi.changelog;
        filter.automaticUpdateFilter = tfi.automaticUpdateFilter;
        filter.latestFilter = tfi.latestFilter;
      }
    }
    return filter;
  }

  /** Limpia los parámetros nulos o vacíos. */
  private clearParams() {
    if (this.search === "" || this.search === null) {
      this.search = undefined;
    }
    if (this.fwid === "" || this.fwid === null) {
      this.fwid = undefined;
    }
    if (this.devicefamily === null) {
      this.devicefamily = undefined;
    }
    if (this.devicetype === null) {
      this.devicetype = undefined;
    }
    if (this.versionSW === "" || this.versionSW === null) {
      this.versionSW = undefined;
    }
    if (this.dateValueStart === "" || this.dateValueStart === null) {
      this.dateValueStart = undefined;
    }
    if (this.dateValueEnd === "" || this.dateValueEnd === null) {
      this.dateValueEnd = undefined;
    }
    if (this.changelog === "" || this.changelog === null) {
      this.changelog = undefined;
    }
    if (
      this.automaticUpdateFilter === false ||
      this.automaticUpdateFilter === null
    ) {
      this.automaticUpdateFilter = undefined;
    }
    if (this.latestFilter === false || this.latestFilter === null) {
      this.latestFilter = undefined;
    }
  }

  public filter(data: FirmwareInterface[]): FirmwareInterface[] {
    this.save();
    let count = 0;
    if (this.fwid) {
      count++;
    }
    if (this.devicefamily) {
      count++;
    }
    if (this.devicetype) {
      count++;
    }
    if (this.versionSW) {
      count++;
    }
    if (this.dateValueStart) {
      count++;
    }
    if (this.dateValueEnd) {
      count++;
    }
    if (this.changelog) {
      count++;
    }
    if (this.automaticUpdateFilter) {
      count++;
    }
    if (this.latestFilter) {
      count++;
    }
    this.count = count;

    return data.filter((item) => {
      // Firmware ID
      if (this.fwid) {
        if (!item.id.includes(this.fwid)) {
          return false;
        }
      }
      // Familia
      if (this.devicefamily) {
        if (
          item.family.toLowerCase() !== this.devicefamily.name.toLowerCase()
        ) {
          return false;
        }
      }
      // Tipo de dispositivo
      if (this.devicetype) {
        if (
          !item.type ||
          item.type.toLowerCase() !== this.devicetype.name.toLowerCase()
        ) {
          return false;
        }
      }
      // Build
      if (this.versionSW) {
        if (
          !item.versionSW ||
          !item.versionSW.toLowerCase().includes(this.versionSW.toLowerCase())
        ) {
          return false;
        }
      }
      // Fecha de alta
      if (this.dateValueStart) {
        const createAt = moment(item.createdAt).startOf("day");
        const dateStart = moment(this.dateValueStart, "YYYY-MM-DD").startOf(
          "day"
        );
        if (createAt.isBefore(dateStart)) {
          return false;
        }
      }

      if (this.dateValueEnd) {
        const createAt = moment(item.createdAt).startOf("day");
        const dateEnd = moment(this.dateValueEnd, "YYYY-MM-DD").startOf("day");
        if (createAt.isAfter(dateEnd)) {
          return false;
        }
      }
      // ChangeLog
      if (this.changelog) {
        if (
          !item.changelog ||
          !item.changelog.toLowerCase().includes(this.changelog.toLowerCase())
        ) {
          return false;
        }
      }
      // Actualización automática
      if (this.automaticUpdateFilter) {
        return item.hwList.some((hw) => hw?.automaticUpdate);
      }
      // Última versión
      if (this.latestFilter) {
        return item.hwList.some((hw) => hw?.latest);
      }
      // Search
      if (this.search) {
        const search = this.search.toLowerCase();
        if (
          !item.id.toLowerCase().includes(search) &&
          !item.versionSW.toLowerCase().includes(search) &&
          (!item.changelog || !item.changelog.toLowerCase().includes(search))
        ) {
          return false;
        }
      }

      return true;
    });
  }
}
