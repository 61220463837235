import UsersFilterEntity from "@/views/Users/UsersFilter/UsersFilterEntity";
import { AxiosHelper } from "../helpers/AxiosHelper";
import PhoneInfoInterface from "./PhoneInfoInterface";
import UserTableInterface from "./UserTableInterface";
import axios, { AxiosRequestConfig, CreateAxiosDefaults } from "axios";

export default class UserService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/user/api/v1";
  }

  public async getUserIdByEmail(email: string): Promise<string> {
    const url = this.urlBase + "/userid?email=" + encodeURIComponent(email);
    return this.axiosService.get(url);
  }

  public async getPhoneInfo(userId: string): Promise<PhoneInfoInterface[]> {
    const url =
      process.env.VUE_APP_PRE_URI +
      "/notification/api/v1/apptoken?userId=" +
      userId +
      "&page=0&size=2000&sort=updateDate,desc&active=true";
    return this.axiosService.get(url).then((response) => {
      return response.content;
    });
  }

  public async getUsers(
    filter: UsersFilterEntity
  ): Promise<UserTableInterface> {
    const url = this.urlBase + "/users" + filter.getParams();
    return this.axiosService.get(url);
  }

  /**
   * Se manda un correo electrónico al usuario para que pueda resetear su contraseña.
   * Para ello es necesario un token especial que solo se utiliza para este caso.
   */
  public async resetPassword(email: string): Promise<void> {
    // Se obtiene el token de las variable que se guardan como propiedades de "window".
    const token = (window as any).frontenv.FRONT_AUTH;
    // Se configura el axios con el token y la url base.
    const configAxios: CreateAxiosDefaults = {
      baseURL: process.env.VUE_APP_PRE_URI,
      headers: {
        Authorization: "Basic " + token,
      },
    };
    // Se crea el axios con la configuración.
    const myAxios = axios.create(configAxios);
    // Se configura la petición post con el email del usuario y la url destino.
    const configRequest: AxiosRequestConfig = {
      method: "post",
      url: "/user/public/api/v4/forgotpassword",
      data: { email },
    };
    // Se realiza la petición.
    return myAxios.request(configRequest);
  }
}
