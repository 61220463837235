/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";
import ExportCell from "@/domain/Export/ExportCell";
import JobEntity from "@/domain/entities/JobEntity";
import JobModel from "../models/JobModel";

export default class ExportJobs<T> extends ExportBase<T> {
  constructor(title: string) {
    super(title);
    this.generateHeaders();
  }

  private generateHeaders(): void {
    // Fecha de creación.
    this.exportTable.addHeader({
      key: "createdDate",
      label: t("jobs.jobcreatedtime"),
      display: (value: string) => {
        return this.dateFormat(Number(value));
      },
    });
    // Fecha de finalización.
    this.exportTable.addHeader({
      key: "endDate",
      label: t("jobs.jobendtime"),
      display: (value: string) => {
        return this.dateFormat(Number(value));
      },
    });
    // Nombre.
    this.exportTable.addHeader({
      key: "jobName",
      label: t("jobs.jobname"),
    });
    // Exitosos.
    this.exportTable.addHeader({
      key: "succeeded",
      label: t("jobs.jobsucceeded"),
    });
    // Fallados.
    this.exportTable.addHeader({
      key: "failed",
      label: t("jobs.jobfailed"),
    });
    // En progreso.
    this.exportTable.addHeader({
      key: "",
      label: t("jobs.status.running"),
      display: (_value: string, item?: T) => {
        const itemT = item as any;
        const r =
          itemT.numDevices - itemT.succeeded - itemT.failed - itemT.cancelled <
          0
            ? 0
            : itemT.numDevices -
              itemT.succeeded -
              itemT.failed -
              itemT.cancelled;
        return r.toString();
      },
    });
    // Cancelados.
    this.exportTable.addHeader({
      key: "cancelled",
      label: t("jobs.status.cancelled"),
    });
    // Versión.
    this.exportTable.addHeader({
      key: "firmware",
      label: t("general.version"),
      display: (_value: string, item?: T) => {
        const itemT = item as any;
        let fw = "";
        if (itemT.firmware != null) {
          if (itemT.firmware.family != null) {
            fw += itemT.firmware.family + " ";
          }
          if (itemT.firmware.type != null) {
            fw += itemT.firmware.type + " ";
          }
          if (itemT.firmware.subtype != null) {
            fw += itemT.firmware.subtype + " ";
          }
          if (itemT.firmware.versionSW != null) {
            fw += itemT.firmware.versionSW;
          }
        }
        return fw;
      },
    });
    // Actualización automática.
    this.exportTable.addHeader({
      key: "automaticUpdate",
      label: t("firmware.automaticUpdate"),
      display: this.displayYesNo.bind(this),
    });
    // Usuario.
    this.exportTable.addHeader({
      key: "userName",
      label: t("jobs.jobuser"),
    });
    // Estado.
    this.exportTable.addHeader({
      key: "status",
      label: t("jobs.jobstatus"),
      display: (value: string) => {
        const jobEntity = new JobEntity({ status: value });
        const jobModel = new JobModel(jobEntity);
        return jobModel.getStatusText();
      },
      styles: (value: string, cell: ExportCell) => {
        const jobEntity = new JobEntity({ status: value });
        const jobModel = new JobModel(jobEntity);
        cell.setColor(this.colorWhite);
        cell.setBgColor(jobModel.getStatusColorPdf().substring(1));
      },
    });
    // ID trabajo.
    this.exportTable.addHeader({
      key: "id",
      label: t("jobs.jobid"),
    });
  }
}
