import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import UsersFilterEntity from "./UsersFilterEntity";

@Component({
  components: {
    AppAutocomplete,
    AppInputDate,
    AppSelect,
    AppFilterButtons,
  },
})
export default class UsersFilter extends Vue {
  @Prop()
  public filter!: UsersFilterEntity;

  public onFilterSubmit(e) {
    e.preventDefault();
    this.$emit("filter", this.filter);
  }

  public cleanFilters() {
    this.filter.clear();
    this.$emit("filter", this.filter);
  }
}
