import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import * as i18nIsoCountries from "i18n-iso-countries";
import i18n from "@/lang/i18n";
import VueI18n from "vue-i18n";
import { LoadOptions } from "devextreme/data";

interface VueOptionsItems {
  value: string;
  label: string | VueI18n.TranslateResult;
}

export default class UsersFilterEntity {
  public perPage: number;
  public page: number;
  public sortField: string;
  public sortOrder: number;
  public search: string | undefined | null;
  public emailFilter: string | undefined | null;
  public langFilter: string | undefined | null;
  public countryFilter: string | undefined | null;
  public dateValueStart: string | undefined | null;
  public dateValueEnd: string | undefined | null;
  public privacyFilter: string | undefined | null;
  public sharingFilter: string | undefined | null;
  public pairingFilter: string | undefined | null;
  public pairingType: string | undefined | null;
  public count: number;
  public paymentFilter: string | undefined | null;
  public providerFilter: string | undefined | null;
  private storeSave: boolean;

  private constructor() {
    this.perPage = 10;
    this.page = 1;
    this.sortField = "createdAt";
    this.sortOrder = -1;
    this.count = 0;
    this.storeSave = true;
  }

  /** Limpia los parámetros de búsqueda. */
  public clear() {
    this.search = undefined;
    this.emailFilter = undefined;
    this.langFilter = undefined;
    this.countryFilter = undefined;
    this.dateValueStart = undefined;
    this.dateValueEnd = undefined;
    this.privacyFilter = undefined;
    this.sharingFilter = undefined;
    this.pairingFilter = undefined;
    this.pairingType = undefined;
    this.paymentFilter = undefined;
    this.providerFilter = undefined;
  }

  /** Obtiene el código del idioma. */
  private getLanguageCode(): string {
    return i18n.locale;
  }

  /** Obtiene una traducción. */
  private t(key: string): VueI18n.TranslateResult {
    return i18n.t(key);
  }

  /** Obtiene las opciones de filtro de idioma. */
  public get langFilterOptions(): Array<VueOptionsItems> {
    const options: Array<VueOptionsItems> = [];
    const names = i18nIsoLanguages.getNames(this.getLanguageCode());

    for (const key in names) {
      options.push({
        value: key,
        label: names[key],
      });
    }
    return options;
  }

  /** Obtiene las opciones de filtro de país. */
  public get countryFilterOptions(): Array<VueOptionsItems> {
    const options: Array<VueOptionsItems> = [];
    const names = i18nIsoCountries.getNames(i18n.locale);

    for (const key in names) {
      options.push({
        value: key,
        label: names[key],
      });
    }
    return options;
  }

  /** Obtiene las opciones de filtro de privacidad. */
  public get privacyFilterOptions(): Array<VueOptionsItems> {
    return [
      { label: this.t("general.yes"), value: "true" },
      { label: this.t("general.no"), value: "false" },
    ];
  }

  /** Obtiene las opciones de filtro de compartir. */
  public get sharingFilterOptions(): Array<VueOptionsItems> {
    return [
      { label: this.t("general.yes"), value: "true" },
      { label: this.t("general.no"), value: "false" },
    ];
  }

  /** Obtiene las opciones de filtro de emparejamiento. */
  public get pairingTypeOptions(): Array<VueOptionsItems> {
    return [
      {
        label: this.t("user.deviceType.wifi"),
        value: "WIFI",
      },
      { label: this.t("user.deviceType.nowifi"), value: "NO_WIFI" },
      { label: this.t("user.deviceType.both"), value: "BOTH" },
      { label: this.t("user.deviceType.none"), value: "NONE" },
    ];
  }

  /** Obtiene las opciones de filtro de pago. */
  public get paymentFilterOptions(): Array<VueOptionsItems> {
    return [
      { label: this.t("general.yes"), value: "true" },
      { label: this.t("general.no"), value: "false" },
    ];
  }

  /** Obtiene las opciones de filtro de proveedor. */
  public get providerFilterOptions(): Array<VueOptionsItems> {
    return [
      { label: "Blue", value: "BLUE" },
      { label: "Opendit", value: "OPENDIT" },
    ];
  }

  /**
   * Limpia los parámetros nulos o vacíos y los guarda en el
   * store del navegador. Si storeSave es falso, no se guardan
   * pero sí se limpian.
   */
  private save() {
    this.clearParams();
    if (this.storeSave) {
      const dataToSave = {
        search: this.search,
        emailFilter: this.emailFilter,
        langFilter: this.langFilter,
        countryFilter: this.countryFilter,
        dateValueStart: this.dateValueStart,
        dateValueEnd: this.dateValueEnd,
        privacyFilter: this.privacyFilter,
        sharingFilter: this.sharingFilter,
        pairingFilter: this.pairingFilter,
        pairingType: this.pairingType,
        paymentFilter: this.paymentFilter,
        providerFilter: this.providerFilter,
      };
      localStorage.setItem("Users.filterOptions", JSON.stringify(dataToSave));
    }
  }

  /**
   * Obtiene el filtro. Si storeSave es verdadero,
   * se obtienen los datos guardados en el store del navegador.
   */
  public static getFilter(storeSave = true): UsersFilterEntity {
    const filter = new UsersFilterEntity();
    filter.storeSave = storeSave;
    if (filter.storeSave) {
      const filterStorage = localStorage.getItem("Users.filterOptions");
      if (filterStorage) {
        const tfi: UsersFilterEntity = JSON.parse(filterStorage);
        filter.search = tfi.search;
        filter.emailFilter = tfi.emailFilter;
        filter.langFilter = tfi.langFilter;
        filter.countryFilter = tfi.countryFilter;
        filter.dateValueStart = tfi.dateValueStart;
        filter.dateValueEnd = tfi.dateValueEnd;
        filter.privacyFilter = tfi.privacyFilter;
        filter.sharingFilter = tfi.sharingFilter;
        filter.pairingFilter = tfi.pairingFilter;
        filter.pairingType = tfi.pairingType;
        filter.paymentFilter = tfi.paymentFilter;
        filter.providerFilter = tfi.providerFilter;
      }
    }
    return filter;
  }

  /** Limpia los parámetros nulos o vacíos. */
  private clearParams() {
    if (this.search === "" || this.search === null) {
      this.search = undefined;
    }
    if (this.emailFilter === "" || this.emailFilter === null) {
      this.emailFilter = undefined;
    }
    if (this.langFilter === "" || this.langFilter === null) {
      this.langFilter = undefined;
    }
    if (this.countryFilter === "" || this.countryFilter === null) {
      this.countryFilter = undefined;
    }
    if (this.dateValueStart === "" || this.dateValueStart === null) {
      this.dateValueStart = undefined;
    }
    if (this.dateValueEnd === "" || this.dateValueEnd === null) {
      this.dateValueEnd = undefined;
    }
    if (this.privacyFilter === "" || this.privacyFilter === null) {
      this.privacyFilter = undefined;
    }
    if (this.sharingFilter === "" || this.sharingFilter === null) {
      this.sharingFilter = undefined;
    }
    if (this.pairingFilter === "" || this.pairingFilter === null) {
      this.pairingFilter = undefined;
    }
    if (this.pairingType === "" || this.pairingType === null) {
      this.pairingType = undefined;
    }
    if (this.paymentFilter === "" || this.paymentFilter === null) {
      this.paymentFilter = undefined;
    }
    if (this.providerFilter === "" || this.providerFilter === null) {
      this.providerFilter = undefined;
    }
  }

  /** Obtiene los parámetros de búsqueda para url. */
  public getParams(): string {
    this.save();
    let params = "";
    let count = 0;

    params += "?page=" + this.page;
    params += "&size=" + this.perPage;

    if (this.sortField && this.sortOrder) {
      params +=
        "&sort=" +
        this.sortField +
        "," +
        (this.sortOrder === 1 ? "asc" : "desc");
    }

    if (this.search) {
      params += "&question=" + encodeURIComponent(this.search);
    }

    if (this.emailFilter) {
      params += "&email=" + encodeURIComponent(this.emailFilter);
      count++;
    }
    if (this.langFilter) {
      params += "&locale=" + encodeURIComponent(this.langFilter);
      count++;
    }
    if (this.countryFilter) {
      params += "&country=" + encodeURIComponent(this.countryFilter);
      count++;
    }
    if (this.dateValueStart) {
      params +=
        "&createDateStart=" +
        encodeURIComponent(this.dateValueStart) +
        " 00:00:00";
      count++;
    }
    if (this.dateValueEnd) {
      params +=
        "&createDateEnd=" + encodeURIComponent(this.dateValueEnd) + " 23:59:59";
      count++;
    }
    if (this.privacyFilter) {
      params += "&Acceptprivacy=" + encodeURIComponent(this.privacyFilter);
      count++;
    }
    if (this.sharingFilter) {
      params += "&AcceptSharing=" + encodeURIComponent(this.sharingFilter);
      count++;
    }
    if (this.pairingFilter) {
      params += "&pairing=" + encodeURIComponent(this.pairingFilter);
      count++;
    }
    if (this.pairingType) {
      params += "&pairingType=" + encodeURIComponent(this.pairingType);
      count++;
    }
    if (this.paymentFilter) {
      params +=
        "&activePremiumSubscription=" + encodeURIComponent(this.paymentFilter);
      count++;
    }
    if (this.providerFilter) {
      params += "&provider=" + encodeURIComponent(this.providerFilter);
      count++;
    }
    this.count = count;
    return params;
  }

  /** Establece los parámetros paginación y ordenación por las
   * opciones de carga de datos de DevExtreme. */
  public setParamsByLoadOptions(loadOptions: LoadOptions) {
    this.perPage = loadOptions.take ?? 10;
    this.page = Math.ceil(
      ((loadOptions.skip ?? 0) + 1) / (loadOptions.take ?? 0)
    );

    this.sortOrder = loadOptions.sort?.[0]?.desc ?? true ? -1 : 1;

    let sort = loadOptions.sort?.[0]?.selector ?? "createdAt";
    if (typeof sort === "function") {
      sort = sort();
    }
    this.sortField = sort;
  }

  /** Clone el filtro. */
  public clone(): UsersFilterEntity {
    const clonedFilter = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this
    );
    return clonedFilter;
  }
}
