<template lang="html" src="./Jobs.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Ref } from "vue-property-decorator";
import { mapActions } from "vuex";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppSelect from "@/components/AppSelect.vue";

import FirmwareVersionSelect from "@/views/Firmware/FirmwareVersionSelect/FirmwareVersionSelect.vue";

import { displayErrorMessage } from "@/api";
import jobService from "@/api/job";

import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";

import dateMixin from "@/mixins/date.mixin";
import filterMixin from "@/mixins/filter.mixin";
import tableBackMixin from "@/mixins/table.mixin/back";

import JobCreateModal from "./JobCreateModal/JobCreateModal.vue";
import JobCancelModal from "./JobCancelModal/JobCancelModal.vue";
import JobDeleteModal from "./JobDeleteModal/JobDeleteModal.vue";

import JobsTable from "./JobsTable/JobsTable.vue";
import JobsService from "@/api/job";
import {
  DeviceFamilyInterface,
  DeviceSubtypeInterface,
  DeviceTypeInterface,
} from "@/interfaces/devices";
import DeviceController from "@/domain/controllers/DeviceController";
import JobModel from "@/ui/models/JobModel";

export interface NumJobs {
  total: number;
  inProgress: number;
}

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Jobs";
const keySearch = keyView + ".search";
const keyFilterOptions = keyView + ".filterOptions";

export const FILTER_DEFINITIONS = {
  idFilter: {
    literal: "jobs.jobid",
  },
  nameFilter: {
    literal: "jobs.jobname",
  },
  statusFilter: {
    literal: "jobs.jobstatus",
    valuesLiteral: JobModel.getStatusCombobox(),
  },
  dateValueStart: {
    literal: "jobs.rangestart",
    format: "date",
  },
  dateValueEnd: {
    literal: "jobs.rangeend",
    format: "date",
  },
  failFilter: {
    literal: "jobs.jobwithfails",
  },
};

@Component({
  components: {
    AppBreadcrumbs,
    FilterTableHeader,
    AppListMenu,
    AppFilterButtons,
    AppKpiChipInfo,
    AppInputDate,
    AppSelect,
    FirmwareVersionSelect,
    JobCreateModal,
    JobCancelModal,
    JobDeleteModal,
    JobsTable,
  },
  mixins: [dateMixin, tableBackMixin, filterMixin],
  methods: { ...mapActions({ getJobsList: "jobs/getJobsList" }) },
})
export default class Jobs extends Vue {
  $moment!: any;
  totalServerItems!: number;
  search!: string;
  getJobsList!: Function;
  checkHasAnyActiveFilter!: Function;
  saveFilters!: Function;
  transformTablePropsForServerRequest!: Function;
  filterOptions!: any;
  filterOptions_!: any;

  loadingData = false;
  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  tableProps: any = {
    sortBy: ["createdAt"],
    sortDesc: [true],
  };
  newJobDialog = false;
  // Dialog for create a job
  createJobDialog = false;
  // Dialog info for delete a job
  deleteDialog = false;
  currentJob = null;
  isApplying = false;
  cancelJobIdModal = null;
  deleteJobIdModal = null;

  ctrlDevices: DeviceController = new DeviceController();
  familiesLoading = true;
  families: DeviceFamilyInterface[] = [];

  typesLoading = false;
  types: DeviceTypeInterface[] = [];

  subtypesLoading = false;
  subtypes: DeviceSubtypeInterface[] = [];

  /** Referencia a la tabla. */
  @Ref("refJobsTable") jobsTable!: JobsTable;

  mounted() {
    this.familiesLoading = true;
    this.ctrlDevices.getFamilies().then((res) => {
      this.families = res;
      this.familiesLoading = false;
    });
  }

  onChangeFamily(family: DeviceFamilyInterface | undefined) {
    delete this.filterOptions.typeFilter;
    this.types = [];
    delete this.filterOptions.subtypeFilter;
    this.subtypes = [];
    if (family !== undefined) {
      this.typesLoading = true;
      this.ctrlDevices.getTypes(family.name).then((res) => {
        if (family.name === "PANEL") {
          this.types = res.filter((t) => t.name === "CORA");
        } else {
          this.types = res;
        }
        this.typesLoading = false;
      });
    }
  }

  onChangeType(type: DeviceTypeInterface | undefined) {
    delete this.filterOptions.subtypeFilter;
    this.subtypes = [];
    if (type !== undefined) {
      this.subtypesLoading = true;
      this.ctrlDevices
        .getSubtypes(this.filterOptions.familyFilter.name, type.name)
        .then((res) => {
          this.subtypes = res;
          this.subtypesLoading = false;
        });
    }
  }

  isDisabledTypeFilter() {
    return !this.filterOptions.familyFilter;
  }

  isDisabledSubtypeFilter() {
    return (
      !this.filterOptions.typeFilter ||
      this.filterOptions.typeFilter.name === "CORA"
    );
  }

  get menuOptions() {
    return [
      {
        icon: "assignment",
        text: this.$t("jobdetails.title"),
        to: (item) => ({ name: "JobDetails", params: { jobid: item.id } }),
        allowed: this.$ability.can("details", "jobs"),
      },
      {
        icon: "cancel",
        text: this.$t("general.cancel"),
        action: (item) => this.setCancelJobIdModal(item.id),
        allowed: this.$ability.can("cancel", "jobs"),
        allowedForItem: (item) =>
          item.immediate === false && item.status === "INPROGRESS",
      },
      {
        icon: "delete",
        text: this.$t("general.delete"),
        action: (item) => this.setDeleteJobIdModal(item.id),
        allowed: this.$ability.can("delete", "jobs"),
        allowedForItem: (item) =>
          item.status === "COMPLETED" ||
          item.status === "CANCELLED" ||
          item.status === "FAILED",
      },
    ];
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get isCancelModalShow() {
    return !!this.cancelJobIdModal;
  }

  set isCancelModalShow(v) {
    this.cancelJobIdModal = null;
  }

  get isDeleteModalShow() {
    return !!this.deleteJobIdModal;
  }

  set isDeleteModalShow(v) {
    this.deleteJobIdModal = null;
  }

  get statusArray() {
    return JobModel.getStatusCombobox();
  }

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };
    if (filters.failFilter === false) delete filters.failFilter;

    return this.checkHasAnyActiveFilter(filters);
  }

  get jobsList() {
    return this.$store.getters["jobs/jobsList"];
  }

  get numberOfJobs() {
    return this.$store.getters["jobs/numberOfJobs"];
  }

  get numberOfInProgressJobs() {
    return this.$store.getters["jobs/numberOfInProgressJobs"];
  }

  /** Contadores superiores */
  numJobs: NumJobs = {
    total: 0,
    inProgress: 0,
  };

  constructor() {
    super();
    this.loadNumJobs();
  }

  /** Obtiene los contadores superiores. */
  loadNumJobs() {
    JobsService.getNumberOfJobs().then((res) => {
      this.numJobs.total = res.data.totalElements;
    });
    JobsService.getNumberOfJobs({ inprogress: true }).then((res) => {
      this.numJobs.inProgress = res.data.totalElements;
    });
  }

  setCancelJobIdModal(v) {
    this.cancelJobIdModal = v;
  }

  setDeleteJobIdModal(v) {
    this.deleteJobIdModal = v;
  }

  getWithoutPagination(page, size) {
    const pagination = this.transformTablePropsForServerRequest();
    pagination.rowsPerPage = size;
    pagination.page = page;

    return jobService.getJobs(
      this.filterOptions_,
      pagination,
      this.search || null,
      pagination.sortBy
    );
  }

  onCancel() {
    this.refreshList();
    this.jobsTable.refresh();
  }
  onDelete() {
    this.refreshList();
    this.jobsTable.refresh();
  }

  refreshList() {
    this.loadingData = true;

    this.transformTablePropsForServerRequest();

    /*this.getJobsList({
      filterOptions: this.filterOptions_,
      pagination: pagination,
      search: this.search ? this.search.trim() : null,
      column: pagination.sortBy,
    })
      .then((res) => {
        this.$store.dispatch("jobs/getNumberOfJobs");
        this.$store.dispatch("jobs/getNumberOfInProgressJobs");
        this.totalServerItems = res.totalElements;

        setTimeout(() => {
          this.loadingData = false;
        }, 500);
      })
      .catch((err) => {
        console.error(err);
        this.loadingData = false;
      });*/
  }

  onFilterSubmit(e) {
    e.preventDefault();

    if (this.filterOptions.disabledFilter === false)
      delete this.filterOptions.disabledFilter;

    this.saveFilters();

    this.tableProps.page = 1;
    this.refreshList();
  }

  refreshListSearch() {
    this.tableProps.page = 1;

    this.refreshList();
  }

  viewJobDetails(id) {
    return {
      name: "JobDetails",
      params: { jobid: id },
    };
  }

  refreshJob(job) {
    this.loadingData = true;

    jobService
      .refreshJobInfo(job.id)
      .then(() => {
        const worker = (window as any).activeJobsFinishedWorkers[job.id];
        if (worker) worker.postMessage({ cmd: "send_check" });

        this.refreshList();

        this.$store.dispatch("snackbarInfo", {
          text: this.$t("jobs.info.refreshJobInfo"),
        });
      })
      .catch((err) =>
        displayErrorMessage(err, { general: ["general.error.refreshError"] })
      );
  }

  setStyleClass(item) {
    let classStyle;
    if (item === 0) classStyle = "lowOpacity";

    return classStyle;
  }
}
</script>

<style scoped>
.color-label {
  color: black !important;
}
.expansion-panel {
  border: 1px solid #e0e0e0 !important;
}
</style>
