import UserService from "./UserService";
import PhoneInfoEntity from "./PhoneInfoEntity";
import UsersFilterEntity from "@/views/Users/UsersFilter/UsersFilterEntity";
import UserTableInterface from "./UserTableInterface";
import UserInterface from "./UserInterface";

export default class UserController {
  protected service: UserService;

  constructor() {
    this.service = new UserService();
  }

  public async getUserIdByEmail(email: string): Promise<string> {
    return this.service.getUserIdByEmail(email);
  }

  public async getPhoneInfo(userId: string): Promise<PhoneInfoEntity[]> {
    return this.service.getPhoneInfo(userId).then((data) => {
      return data.map((item) => new PhoneInfoEntity(item));
    });
  }

  public async getUsers(
    filter: UsersFilterEntity
  ): Promise<UserTableInterface> {
    return this.service.getUsers(filter);
  }

  public async getUsersRecursively(
    filter: UsersFilterEntity
  ): Promise<UserInterface[]> {
    const filterClone = filter.clone();
    filterClone.perPage = 2000;
    filterClone.page = 1;
    const data: UserInterface[] = [];
    let res: UserTableInterface = { content: [], totalElements: 0 };
    do {
      res = await this.getUsers(filterClone);
      data.push(...res.content);
      filter.page++;
    } while (data.length < res.totalElements);
    return data;
  }

  /** Se manda un correo electrónico al usuario para que pueda resetear su contraseña. */
  public async resetPassword(email: string): Promise<void> {
    return this.service.resetPassword(email);
  }
}
