<template lang="html" src="./InternalUsers.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable no-useless-escape -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mapActions } from "vuex";

import { COMMA_SEPARATOR } from "@/constants";

import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";

import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppChipInfo from "@/components/AppChipInfo.vue";

import dateMixin from "@/mixins/date.mixin";
import tableFrontMixin from "@/mixins/table.mixin/front";
import filterMixin from "@/mixins/filter.mixin";

import InternalUsersTable from "./InternalUsersTable/InternalUsersTable.vue";
import { InternalUserController } from "@/domain/controllers/InternalUserController";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";
import DialogNewInternalUser from "./DialogNewInternalUser/DialogNewInternalUser.vue";
import Toast from "@/ui/helpers/Toast";

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "InternalUsers";
const keySearch = keyView + ".search.";
const keyFilterOptions = keyView + ".filterOptions";

const FILTER_DEFINITIONS = {
  email: {
    literal: "user.email",
  },
  rol: {
    literal: "internalusers.rol",
  },
};

@Component({
  components: {
    AppListMenu,
    AppBreadcrumbs,
    AppChipInfo,
    FilterTableHeader,
    AppFilterButtons,
    AppKpiChipInfo,
    AppInputDate,
    AppSelect,
    InternalUsersTable,
    DialogNewInternalUser,
  },
  mixins: [dateMixin, tableFrontMixin, filterMixin],
  methods: {
    ...mapActions({ getInternalUsers: "internalusers/getInternalUsers" }),
  },
})
export default class InternalUsers extends Vue {
  ctrlInternalUser = new InternalUserController();
  roleList: string[] = [];

  constructor() {
    super();
    this.ctrlInternalUser.getRoles().then((roles) => {
      this.roleList.push(...roles);
    });
  }

  filterInList!: Function;
  filterStartWith!: Function;
  saveFilters!: Function;
  getInternalUsers!: Function;
  checkHasAnyActiveFilter!: Function;
  filterOptions_!: any;

  COMMA_SEPARATOR = COMMA_SEPARATOR;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;

  usersListList: InternalUserEntity[] = [];

  get menuOptions() {
    return [
      {
        icon: "people",
        text: this.$t("user.userDetails"),
        to: (item) => ({ name: "InternalDetails", params: { id: item.id } }),
        allowed: this.$ability.can("details", "internalusers"),
      },
    ];
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };

    return this.checkHasAnyActiveFilter(filters);
  }

  showDialogNew = false;

  cancelDialogNew() {
    this.showDialogNew = false;
  }

  saveDialogNew(internalUser: InternalUserEntity) {
    this.ctrlInternalUser
      .insert(internalUser)
      .then(() => {
        this.refreshList();
        Toast.info("internalusers.newUserAlert");
      })
      .catch(() => {
        Toast.error("oauthclients.error.updateFail");
      });
  }

  refreshList() {
    this.saveFilters();
    this.ctrlInternalUser
      .getAll()
      .then((internalUsers: InternalUserEntity[]) => {
        this.usersListList = this.applyFilterSearchOptions(internalUsers);
      });
  }

  /** Aplica los filtros de búsqueda y los filtros de la tabla */
  applyFilterSearchOptions(
    internalUsers: InternalUserEntity[]
  ): InternalUserEntity[] {
    const searchString: string = this.getSearchText() ?? "";
    const email: string = this.filterOptions_?.email || "";
    const rol: string = this.filterOptions_?.rol || "";

    return internalUsers.filter((internalUser: InternalUserEntity) => {
      return (
        internalUser.searchText(searchString) &&
        internalUser.email.includes(email) &&
        internalUser.roles.some((r) => r.name.includes(rol))
      );
    });
  }

  openDialogNew() {
    this.showDialogNew = true;
  }

  onFilterSubmit(e) {
    e.preventDefault();
    this.saveFilters();
    this.refreshList();
  }

  searchClick() {
    this.refreshList();
  }
  getSearchText() {
    return sessionStorage.getItem(keySearch)?.toLowerCase() ?? null;
  }
}
</script>
