/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import DevicesFilter from "./DevicesFilter/DevicesFilter.vue";

import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";

import deviceService from "@/api/device";

import FullScreenMap from "@/components/FullScreenMap/FullScreenMap.vue";
import MarkerMap from "@/components/MarkerMap/MarkerMap.vue";
import MapFlet from "@/components/MapFlet/MapFlet.vue";

import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";

import StatusChip from "@/components/StatusChip/StatusChip.vue";

import { TAB_TYPE } from "../Devices.vue";

import DeviceProperties from "../DeviceProperties/DeviceProperties.vue";

import QRCode from "@/components/QRCode.vue";

import DerviceTable from "./DerviceTable/DerviceTable.vue";
import DeviceEntity from "@/domain/entities/DeviceEntity";

import TableMarkersToggle from "@/components/TableMarkersToggle/TableMarkersToggle.vue";

import DTITableMarkersMap from "./DTITableMarkersMap/DTITableMarkersMap.vue";
import SessionStorageHelper from "@/ui/helpers/SessionStorageHelper";

const QR_CODE_PATH =
  "https://www.opendit.com/es/connect?utm_source=fermax-phone&utm_medium=sticker&uuid=";

export const getMarkerIcon = (device) => {
  let type = "marker-icon";
  let color = "grey";

  if (device.family === "MONITOR" && device.type) type = device.type;

  if (
    device.family === "PANEL" ||
    (device.family === "GUARDUNIT" && device.type)
  )
    type = device.family;

  if (device.family === "PHONE" && device.type)
    type = device.family + "_" + device.type;

  if (device.status) color = "green";

  return type + "_" + color + ".png";
};

export const getMarkerIconNewMap = (device) => {
  let type = "marker-icon";
  let color = "grey";

  if (device.f === "MONITOR" && device.t) type = device.t;

  if (device.f === "PANEL" || (device.f === "GUARDUNIT" && device.t))
    type = device.f;

  if (device.f === "PHONE" && device.t) type = device.f + "_" + device.t;

  if (device.sts) color = "green";

  return type + "_" + color + ".png";
};

interface FilterOptionsInterface {
  familyFilter?: string | null;
  connectableFilter?: boolean | null;
  statusFilter?: string | null;
}

@Component({
  components: {
    AppListMenu,
    DevicesFilter,
    FullScreenMap,
    MarkerMap,
    StatusChip,
    FilterTableHeader,
    DeviceProperties,
    MapFlet,
    QRCode,
    DerviceTable,
    TableMarkersToggle,
    DTITableMarkersMap,
  },
})
export default class DeviceTabItem extends Vue {
  @Prop() tabIndex!: number;
  @Prop(String) type!: string;

  public filterOptions_: FilterOptionsInterface = {
    familyFilter: null,
    connectableFilter: null,
    statusFilter: null,
  };
  public filterOptions: FilterOptionsInterface = {
    familyFilter: null,
    connectableFilter: null,
    statusFilter: null,
  };
  /** 0=btnlist, 1=btnmap, 2=btnzone */
  public toggleView = 0;

  public TAB_TYPE = TAB_TYPE;

  public fullScreenMarkerMap = false;
  public selectedMonitor = {};
  public disabledFilters = false;
  public devicePropertiesId: string | null = null;
  public isShowDeviceProperties = false;
  public mapMarkerInfo = {
    getName: (o) => o.serialNumber,
    getIcon: getMarkerIcon,
    getIconNewMap: getMarkerIconNewMap,
    isOnline: (o) => o.status === true,
  };

  /** Indica si está abierto el panel de filtros. */
  public expansionPanel: number | null = null;
  /** Cadena de texto del búscador rápido. */
  public search: string | null = null;
  /** Indica si los filtros están desactivados. */
  public isDisabledFilters = false;

  $refs!: {
    monitorsMap: any;
    deviceFilters: any;
    mapFlet: any;
  };
  public searchTable: string | null = null;

  public showQrCode = false;
  public url = "https://www.fermax.com/blue?type=NO_WIFI&uuid=";

  private sSViewMap!: SessionStorageHelper<number>;
  private ssFilterOptions!: SessionStorageHelper<FilterOptionsInterface>;
  private ssSearch!: SessionStorageHelper<string>;

  public get typeTMM() {
    if (this.type === TAB_TYPE.PANELS_4G) {
      return "PANEL";
    } else {
      return "MONITOR";
    }
  }

  public get menuOptions() {
    return [
      {
        icon: "list_alt",
        text: this.$t("menu.deviceDetails"),
        to: (item) => ({
          name: "DeviceDetails",
          params: { deviceId: item.serialNumber },
        }),
        allowed: this.$ability.can("details", "devices"),
      },
      {
        icon: "dns",
        text: this.$t("deviceDetails.showProperties"),
        action: (item) => this.showDeviceProperties(item.serialNumber),
        allowed: this.$ability.can("property", "devices"),
      },
      {
        icon: "place",
        text: this.$t("device.viewOnMap"),
        action: (item) => this.openMarkerMap(item),
        allowed: this.$ability.can("locate", "devices"),
        allowedForItem: (item) => item.deployed,
      },
      {
        icon: "refresh",
        text: this.$t("deviceDetails.refreshView"),
        action: (item) => this.refreshDeviceDetails([item]),
        allowed: this.$ability.can("refresh", "devices"),
      },
      {
        icon: "fa-qrcode",
        text: this.$t("deviceDetails.generateQR"),
        action: (item) => this.qrCode(item),
        allowed: this.$ability.can("qrCode", "devices"),
        allowedForItem: (item) => {
          const deviceEntity = new DeviceEntity();
          deviceEntity.family = item.family;
          deviceEntity.type = item.type;
          return deviceEntity.canGenerateQrCode();
        },
      },
    ];
  }

  /** Indica si se está visualizando la lista. */
  public get isViewList(): boolean {
    return this.toggleView === 0;
  }

  /** Indica si se está visualizando el mapa. */
  public get isViewMap(): boolean {
    return this.toggleView === 1;
  }

  /** Indica si se está visualizando la zona. */
  public get isViewZone(): boolean {
    return this.toggleView === 2;
  }

  public qrCode(item: { serialNumber: string }) {
    this.url = QR_CODE_PATH + item.serialNumber;
    this.showQrCode = true;
  }

  private get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  /** Indica si la tabla es de tipo mapa. */
  public get hasMapLists() {
    return (
      this.type === TAB_TYPE.WIFI_MONITORS ||
      this.type === TAB_TYPE.PANELS_4G ||
      this.type === TAB_TYPE.NOWIFI_MONITORS ||
      this.type === TAB_TYPE.PHONES ||
      this.type === TAB_TYPE.GUARD
    );
  }

  /** Título de la tabla */
  public get tableTitle() {
    const titleList = {
      all: this.$t("device.allList"),
      edibox: this.$t("device.ediboxList"),
      panels4g: this.$t("device.panels4gList"),
      guard: this.$t("device.guard"),
      phones: this.$t("device.phones"),
      monnowifi: this.$t("device.monnowifiList"),
      monwifi: this.$t("device.monwifiList"),
    };
    const titleMap = {
      panels4g: this.$t("device.panelMap"),
      phones: this.$t("device.phoneMap"),
      guard: this.$t("device.guardMap"),
      monnowifi: this.$t("device.monnowifiMap"),
      monwifi: this.$t("device.monwifiMap"),
    };
    return this.isViewMap || this.isViewZone
      ? titleMap[this.type]
      : titleList[this.type];
  }

  public get exportName() {
    return this.type === TAB_TYPE.ALL_DEVICES
      ? this.$t("device.devices")
      : this.$t("device.tab." + this.type);
  }

  /** Indica si hay algún filtro activo */
  public get hasAnyActiveFilter() {
    const filterOptions = { ...this.filterOptions_ };
    if (
      this.type === TAB_TYPE.WIFI_MONITORS ||
      this.type === TAB_TYPE.NOWIFI_MONITORS ||
      this.type === TAB_TYPE.PANELS_4G ||
      this.type === TAB_TYPE.PHONES ||
      this.type === TAB_TYPE.GUARD ||
      this.type === TAB_TYPE.EDIBOX
    ) {
      delete filterOptions.familyFilter;
      delete filterOptions.connectableFilter;
    }
    return this.checkHasAnyActiveFilter(filterOptions);
  }

  private checkHasAnyActiveFilter(filterObj: { [key: string]: any }) {
    return (
      filterObj &&
      Object.values(filterObj).filter(
        (v) => v !== null && v != undefined && v.toString().trim() != ""
      ).length > 0
    );
  }

  @Watch("toggleView")
  onChangeToggleView(newValue: number) {
    if (
      (this.isViewMap || this.isViewZone) &&
      this.filterOptions.statusFilter === "Manufactured"
    ) {
      this.filterOptions.statusFilter = null;
      this.filterOptions_.statusFilter = null;
      this.ssFilterOptions.setItem(this.filterOptions);
    }
    this.sSViewMap.setItem(newValue);
  }

  created() {
    this.sSViewMap = new SessionStorageHelper<number>(
      "Devices.viewMap." + this.type,
      0
    );
    this.ssFilterOptions = new SessionStorageHelper<FilterOptionsInterface>(
      "Devices.filterOptions." + this.type,
      {
        familyFilter: null,
        connectableFilter: null,
        statusFilter: null,
      }
    );
    this.ssSearch = new SessionStorageHelper<string>(
      "Devices.search." + this.type,
      ""
    );

    this.toggleView = this.sSViewMap.getItem();
    this.filterOptions = this.ssFilterOptions.getItem();

    if (this.type === TAB_TYPE.WIFI_MONITORS) {
      this.filterOptions.familyFilter = "MONITOR";
      this.filterOptions.connectableFilter = true;
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.PANELS_4G) {
      this.filterOptions.familyFilter = "PANEL";
      this.filterOptions.connectableFilter = true;
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.NOWIFI_MONITORS) {
      this.filterOptions.familyFilter = "MONITOR";
      this.filterOptions.connectableFilter = false;
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.PHONES) {
      this.filterOptions.familyFilter = "PHONE";
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.GUARD) {
      this.filterOptions.familyFilter = "GUARDUNIT";
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.EDIBOX) {
      this.filterOptions.familyFilter = "EDIBOX";
      this.filterOptions_ = { ...this.filterOptions };
    }
  }

  private getListNewMap(pagination) {
    const allDevice = false;
    const s: any = this.search ? this.search.trim() : null;
    return deviceService.getDevicesMap(
      this.filterOptions_,
      pagination,
      s,
      pagination.sortBy,
      allDevice
    );
  }

  public getWithoutPagination(page: number, size: number) {
    const pagination = {
      rowsPerPage: size,
      page: page,
      sortBy: "serialNumber",
    };
    return this.getListNewMap(pagination);
  }

  private showDeviceProperties(deviceId) {
    this.devicePropertiesId = deviceId;
    this.isShowDeviceProperties = true;
  }

  private refreshList() {
    //this.configureAxiosCancelToken();
    this.searchTable = this.ssSearch.getItem();
  }

  public onSearch(newValue: string) {
    this.ssSearch.setItem(newValue);
    if (this.isViewList || this.isDisabledFilters) {
      this.refreshList();
    }
  }

  // Refresh only one monitor
  private refreshDeviceDetails(selection) {
    /////////////this.loadingData = true;
    let stringMonitorsUrl = "";

    selection.forEach((item) => {
      stringMonitorsUrl += "devices=" + item.serialNumber;
    });

    deviceService.refreshDevice(stringMonitorsUrl).then(
      () => {
        this.refreshList();

        const text = this.$t("device.info.refreshDeviceInfo");
        this.$store.dispatch("snackbarInfo", {
          text: text,
        });

        /********setTimeout(() => {
          this.loadingData = false;
        }, 500);*/
      },
      (error) => {
        ////////////this.loadingData = false;

        this.$store.dispatch("errorHandler", {
          error: error,
          genericErrorText: ["general.error.refreshError"],
        });
      }
    );
  }

  public filterClick() {
    if (this.$refs.monitorsMap && !this.isViewZone)
      this.$refs.monitorsMap.updateMarkers();

    this.saveFilters();
    this.toggleView == 1
      ? this.$refs.mapFlet.loadForMarkers()
      : this.refreshList();
  }

  private openMarkerMap(monitor) {
    this.selectedMonitor = monitor;
    this.fullScreenMarkerMap = true;
  }

  public onCloseHeatMap() {
    this.toggleView = 1;
  }

  public onDisableFilters(disabled: boolean) {
    if (disabled) {
      this.expansionPanel = null;
      this.search = null;

      if (this.$refs.deviceFilters) this.$refs.deviceFilters.cleanFilters();
    }

    this.isDisabledFilters = disabled;
  }

  private saveFilters() {
    this.ssFilterOptions.setItem(this.filterOptions);
    this.filterOptions_ = { ...this.filterOptions };
  }
}
