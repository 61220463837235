import { Location } from "vue-router/types/router";
import PlanInterface, {
  AvailabilityRegion,
  Plan,
  RegionalDetails,
} from "./PlanInterface";
import i18n from "@/lang/i18n";

export default class PlanEntity {
  public plan: Plan;
  public availabilityRegionList: AvailabilityRegion[];
  public regionalDetailsList: RegionalDetails[];
  public upgradeable: boolean;
  public empty: boolean;
  public planId: number;
  public sku: string;
  public trialExternalId: string | null;
  public skuOrExternalId: string;
  public countries: string[];

  constructor(data: PlanInterface) {
    this.plan = data.plan;
    this.availabilityRegionList = data.availabilityRegionList;
    this.regionalDetailsList = data.regionalDetailsList;
    this.upgradeable = data.upgradeable;
    this.empty = data.empty;
    this.planId = data.planId;
    this.sku = data.sku;
    this.trialExternalId = data.trialExternalId;
    this.skuOrExternalId = data.skuOrExternalId;
    this.countries = data.countries;
  }

  /** Indica si el plan es de prueba. */
  public get isTrial(): boolean {
    return this.plan.trial !== null;
  }

  /** Obtiene la URL de los detalles del plan. */
  public get to(): Location {
    return {
      name: "PlanDetails",
      params: { planCode: this.plan.planId.toString() },
    };
  }

  public get code(): string {
    return this.plan.sku;
  }

  public get name(): string {
    const lang = i18n.locale.toUpperCase();
    const defaultLang = (i18n.fallbackLocale as string).toUpperCase();
    let regionDetail = this.regionalDetailsList.find(
      (rd) => rd.language === lang
    );
    if (!regionDetail) {
      regionDetail = this.regionalDetailsList.find(
        (rd) => rd.language === defaultLang
      );
    }
    if (!regionDetail) {
      if (this.regionalDetailsList.length > 0) {
        regionDetail = this.regionalDetailsList[0];
      } else {
        regionDetail = undefined;
      }
    }
    if (regionDetail) {
      return regionDetail.commercialName;
    } else {
      return "";
    }
  }

  public get services(): string {
    return this.plan.services.map((s) => s.name).join(", ");
  }
}
