<template lang="html" src="./OAuthClients.html"></template>

<!-- eslint-disable no-useless-escape -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { COMMA_SEPARATOR } from "@/constants";
import { displayErrorMessage } from "@/api";
import clientService from "@/api/client";

import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppSelect from "@/components/AppSelect.vue";

import { PREDEFINED_STYLES } from "@/mixins/table-export.mixin";

import dateMixin from "@/mixins/date.mixin";
import tableFrontMixin from "@/mixins/table.mixin/front";
import filterMixin from "@/mixins/filter.mixin";

import { translate } from "@/lang/i18n";

import OAuthClientsTable from "./OAuthClientsTable/OAuthClientsTable.vue";

const FILTER_DEFINITIONS = {
  nameFilter: {
    literal: "oauthclients.clientName",
  },
  idFilter: {
    literal: "oauthclients.clientID",
  },
  rolFilter: {
    literal: "oauthclients.rol",
  },
  scopeFilter: {
    literal: "oauthclients.scopes",
  },
  typeFilter: {
    literal: "oauthclients.grantTypes",
  },
  disabledFilter: {
    literal: "oauthclients.status",
  },
};

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "OAuthclients";
const keySearch = keyView + ".search";
const keyFilterOptions = keyView + ".filterOptions";

@Component({
  components: {
    AppBreadcrumbs,
    AppListMenu,
    AppKpiChipInfo,
    FilterTableHeader,
    AppFilterButtons,
    AppSelect,
    OAuthClientsTable,
  },
  mixins: [dateMixin, tableFrontMixin, filterMixin],
  computed: {
    ...mapGetters(["isUserLogged"]),
  },
})
export default class OAuthClients extends Vue {
  $t = translate; // Context wrapper to use translations on <script> at runtime
  filterOptions_!: any;
  filterInList!: Function;
  filterContains!: Function;
  saveFilters!: Function;
  checkHasAnyActiveFilter!: Function;
  loadingData!: boolean;
  currentId: any = null;
  addedClient: any = null;
  secretIdDetails: any = null;
  clientsList: any = null;
  copySucceeded: any = null;

  COMMA_SEPARATOR = COMMA_SEPARATOR;
  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  tableProps: any = {
    sortBy: ["name"],
  };
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  get statusOptions() {
    return [
      this.$t("oauthclients.enable"),
      this.$t("oauthclients.disable"),
      this.$t("oauthclients.all"),
    ];
  }
  dialog = false;
  newClient = false;
  select: any = null;
  // for edit the clients
  editDialog = false;
  validEditForm = true;
  currentClient: any = null;
  currentUris: any = null;
  detailsEdit = "";
  currentDetails: any = null;
  clientIdDetails = "";
  uriname = "";
  insertDialog = false;
  cliName: string | null = "";
  cliId: string | null = "";
  currentSecretId: string | null = "";
  currentName = "";
  currentAccessToken = "";
  currentRefreshToken: any = "";
  cliUri = [];
  allUris: Array<any> | null = [];
  cliRefresh: string | null = "";
  cliAccess: string | null = "";
  dialogSteps = 1;
  validFormStep1 = false;
  validFormStep2 = false;
  validFormStep3 = false;
  validUrisForm = false;
  infoDialog = false;
  urisDialog = false;
  insertBtn = false;
  secretDialog = false;
  result = 0;
  cliEmail = "";
  cliWac = "";
  grantTypesDialog = false;
  currentGrantTypes: any = null;
  confirmGrantTypeDialog = false;

  $refs!: {
    uriForm: HTMLFormElement;
    formStep1: HTMLFormElement;
    formStep2: HTMLFormElement;
    formStep3: HTMLFormElement;
  };

  get menuOptions() {
    return [
      {
        icon: "info",
        text: this.$t("general.details"),
        action: (item) => this.prepareToCopy(item),
        allowed: this.$ability.can("details", "oauthclients"),
      },
      {
        icon: "edit",
        text: this.$t("general.edit"),
        action: (item) => this.prepareToEdit(item),
        allowed: this.$ability.can("edit", "oauthclients"),
      },
      {
        icon: "add",
        text: this.$t("oauthclients.redirecturis"),
        action: (item) => this.prepareUris(item),
        allowed: this.$ability.can("edit", "oauthclients"),
      },
      {
        icon: "https",
        text: this.$t("oauthclients.editGrantTypes"),
        action: (item) => this.prepareGrantTypes(item),
        allowed: this.$ability.can("edit", "oauthclients"),
      },
    ];
  }

  getSearchText() {
    return sessionStorage.getItem(keySearch)?.toLowerCase() ?? null;
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get clientsInfo() {
    const listInfo = { ...this.$store.getters["oauthclients/clientsInfo"] };

    if (this.filterOptions_ && listInfo.list)
      listInfo.list = listInfo.list.filter((i) =>
        this.filterClient(i, this.filterOptions_)
      );

    listInfo.list = listInfo.list.map((i) => ({
      ...i,
      roles: i.roles.map((r) => r.name).join(COMMA_SEPARATOR),
      scopes: i.scopes.map((s) => s.name).join(COMMA_SEPARATOR),
      grantTypes: i.grantTypes.map((g) => g.name).join(COMMA_SEPARATOR),
    }));

    const searchString = this.getSearchText();
    if (searchString !== null) {
      this.getListInfoList = listInfo.list.filter((i) => {
        let include = false;
        if (i.name.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (i.clientId.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (i.roles.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (i.scopes.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (i.grantTypes.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (
          i.accessTokenValiditySeconds
            .toString()
            .toLowerCase()
            .includes(searchString)
        ) {
          include = true;
        }
        if (
          i.refreshTokenValiditySeconds
            .toString()
            .toLowerCase()
            .includes(searchString)
        ) {
          include = true;
        }
        return include;
      });
    } else {
      this.getListInfoList = listInfo.list;
    }

    return listInfo;
  }
  searchClick() {
    this.refreshList();
  }

  getListInfoList: Array<any> = [];

  get hasAnyActiveFilter() {
    return this.checkHasAnyActiveFilter(this.filterOptions_);
  }

  get notEmptyRule() {
    // !!v = I'ts not null, empty or undefined
    return [(v) => !!v || this.$t("firmware.fieldrequired")];
  }

  get numberRules() {
    return [
      (v) => !!v || this.$t("firmware.fieldrequired"),
      (v) => !isNaN(v) || this.$t("oauthclients.rules.onlynumbers"),
      (v) => v >= 0 || this.$t("oauthclients.rules.positive"),
    ];
  }

  get positiveRules() {
    return [(v) => v >= 0 || this.$t("oauthclients.rules.positive")];
  }

  get urisRules() {
    return [
      (v) => !!v || this.$t("firmware.fieldrequired"),
      (v) =>
        /^(http|https):\/\/(([a-zA-Z0-9$\-_.+!*'(),;:&=]|%[0-9a-fA-F]{2})+@)?(((25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])(\.(25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])){3})|localhost|([a-zA-Z0-9\-\u00C0-\u017F]+\.)+([a-zA-Z]{2,}))(:[0-9]+)?(\/(([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*(\/([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*)*)?(\?([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?(\#([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?)?$/.test(
          v
        ) || this.$t("oauthclients.rules.urlformat"),
    ];
  }

  get nameRules() {
    return [
      (v) => !!v || this.$t("firmware.fieldrequired"),
      (v) =>
        !v ||
        v.length <= 32 ||
        this.$t("oauthclients.rules.clientNameMaxLength"),
    ];
  }

  // for return all data by the table
  get fieldsList() {
    const headers: any = [
      {
        text: this.$t("oauthclients.clientName"),
        value: "name",
        sortable: true,
        align: "left",
      },
      {
        text: this.$t("oauthclients.clientID"),
        value: "clientId",
        sortable: true,
        align: "left",
        export: {
          width: 80,
        },
      },
      {
        text: this.$t("oauthclients.rol"),
        value: "roles",
        sortable: false,
        align: "left",
        export: {
          width: 90,
        },
      },
      {
        text: this.$t("oauthclients.scopes"),
        value: "scopes",
        sortable: false,
        align: "left",
        export: {
          width: 90,
        },
      },
      {
        text: this.$t("oauthclients.grantTypes"),
        value: "grantTypes",
        sortable: false,
        align: "left",
        export: {
          width: 90,
        },
      },
      {
        text: this.$t("oauthclients.accessTokenTime"),
        value: "accessTokenValiditySeconds",
        sortable: true,
        align: "left",
        export: {
          format: "number",
        },
      },
      {
        text: this.$t("oauthclients.refreshToken"),
        value: "refreshTokenValiditySeconds",
        sortable: true,
        align: "left",
        export: {
          value: (row) =>
            row.refreshTokenValiditySeconds > 0
              ? row.refreshTokenValiditySeconds.toString()
              : "∞",
        },
      },
      {
        text: this.$t("oauthclients.enabled"),
        value: "enable",
        sortable: true,
        align: "left",
        export: {
          width: 25,
          value: (row) =>
            row.enable ? this.$t("general.yes") : this.$t("general.no"),
          style: (row) =>
            row.enable ? PREDEFINED_STYLES.SUCCESS : PREDEFINED_STYLES.FAILED,
        },
      },
    ];

    if (this.menuOptionsAllowed)
      headers.push({
        text: null,
        value: null,
        sortable: false,
        align: "right",
        width: "1%",
        sticky: true,
      });

    return headers;
  }

  @Watch("clientsList")
  onChangeClientsList() {
    if (this.clientsList === null) {
      console.error("ERROR");
    } else {
      if (this.currentUris) {
        const currentUris = this.clientsList.filter(
          (item) => item.clientId === this.currentUris.clientId
        )[0];

        this.allUris = currentUris.uris;
      }
    }
  }

  refreshTokenSort(items, index, isDescending) {
    items.sort((a, b) => {
      if (index[0] === "refreshTokenValiditySeconds") {
        let sortA = a.refreshTokenValiditySeconds;
        let sortB = b.refreshTokenValiditySeconds;

        if (sortA === 0) {
          sortA = Infinity;
        }
        if (sortB === 0) {
          sortB = Infinity;
        }

        if (isDescending[0]) {
          return sortB - sortA;
        } else {
          return sortA - sortB;
        }
      } else {
        const collator = new Intl.Collator("en", {
          numeric: true,
          sensitivity: "base",
        });
        if (isDescending[0]) {
          return collator.compare(b[index[0]], a[index[0]]);
        } else {
          return collator.compare(a[index[0]], b[index[0]]);
        }
      }
    });
    return items;
  }

  // function for the copy paste by a icon
  handleCopyStatus(status) {
    this.copySucceeded = status;
  }

  // function for the permarefresh
  refreshList() {
    this.saveFilters();

    this.loadingData = true;
    this.$store
      .dispatch("oauthclients/getClientsList")
      .then(() => (this.loadingData = false));
  }

  onFilterSubmit(e) {
    e.preventDefault();

    this.tableProps.page = 1;

    this.refreshList();
  }

  filterClient(item, filters) {
    // name
    if (filters.nameFilter) {
      const show = this.filterContains(item.name, filters.nameFilter);

      if (!show) return false;
    }

    // idClient
    if (filters.idFilter) {
      const show = this.filterContains(item.clientId, filters.idFilter);

      if (!show) return false;
    }

    // roles
    if (filters.rolFilter) {
      const show = this.filterInList(item.roles, "name", filters.rolFilter);

      if (!show) return false;
    }

    // timeAccess
    if (filters.timeAccessFilter) {
      const show = item.accessTokenValiditySeconds === filters.timeAccessFilter;

      if (!show) return false;
    }

    // scopes
    if (filters.scopeFilter) {
      const show = this.filterInList(item.scopes, "name", filters.scopeFilter);

      if (!show) return false;
    }

    // grantTypes
    if (filters.typeFilter) {
      const show = this.filterInList(
        item.grantTypes,
        "name",
        filters.typeFilter
      );

      if (!show) return false;
    }

    // enabled
    if (filters.disabledFilter) {
      let show: boolean | null = null;

      if (filters.disabledFilter === this.$t("oauthclients.enable")) {
        show = item.enable === true;
      } else if (filters.disabledFilter === this.$t("oauthclients.disable")) {
        show = item.enable === false;
      }

      if (!show) return false;
    }

    return true;
  }

  onCopyClientId() {
    this.$store.dispatch("snackbarInfo", {
      text: this.$t("oauthclients.clientcopied"),
    });
  }

  onCopyClientSecret() {
    this.$store.dispatch("snackbarInfo", {
      text: this.$t("oauthclients.secretcopied"),
    });
  }

  prepareToCopy(oauthclients) {
    this.dialog = true;
    this.currentDetails = oauthclients;
    this.clientIdDetails = this.currentDetails.clientId;
    this.secretIdDetails = this.currentDetails.clientSecret;
  }

  prepareToEdit(oauthclients) {
    this.editDialog = true;
    this.currentClient = oauthclients;
    this.currentAccessToken = this.currentClient.accessTokenValiditySeconds;
    this.currentId = this.currentClient.clientId;
    this.currentSecretId = this.currentClient.clientSecret;
    this.currentRefreshToken = this.currentClient.refreshTokenValiditySeconds;
    this.currentName = this.currentClient.name;
  }

  itsInfinite() {
    if (this.currentRefreshToken === 0) {
      this.currentRefreshToken = Infinity;
    }
  }

  editClientProperties() {
    this.infoDialog = false;
    this.editDialog = false;
    this.itsInfinite();
    const editProps = {
      name: this.currentName,
      accessTokenValiditySeconds: this.currentAccessToken,
      clientId: this.currentId,
      clientSecret: this.currentSecretId,
      refreshTokenValiditySeconds: this.currentRefreshToken,
    };

    // To update the properties in edit
    clientService
      .setPropertiesEdit(this.currentClient.clientId, editProps)
      .then(() => {
        this.refreshList();

        this.currentClient = null;
      })
      .catch((err) => {
        this.currentClient = null;

        displayErrorMessage(err, {
          general: ["oauthclients.error.updateFail"],
        });
      });

    this.currentClient = null;
    this.refreshList();
  }

  cancelEdit() {
    this.editDialog = false;
    this.currentDetails = null;
  }

  // function for generate random idsecret
  generateSecret() {
    const x =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    this.currentSecretId = x;

    this.$store.dispatch("snackbarInfo", {
      text: this.$t("oauthclients.secretgenerated"),
    });
    let length = 44;
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.currentSecretId = result;
  }

  prepareUris(oauthclients) {
    this.urisDialog = true;
    this.currentUris = oauthclients;
    this.allUris = this.currentUris.uris;
  }

  addRedirectUris() {
    // To add new redirect uri
    clientService
      .setUrisEdit(this.uriname, this.currentUris.clientId)
      .then(() => {
        this.refreshList();
        this.resetFormValues();

        this.$refs.uriForm.reset();
        this.allUris!.push({ name: this.uriname });

        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("oauthclients.newuri"),
        });
        this.urisDialog = false;
      })
      .catch((err) => {
        console.error(err);
        displayErrorMessage(err, {
          general: ["oauthclients.error.updateFail"],
        });
        this.urisDialog = false;
      });
  }

  cancelUris() {
    this.urisDialog = false;
    this.currentUris = null;
    this.allUris = null;
    this.currentUris = null;

    this.resetFormValues();
  }

  cancelInsert() {
    this.insertDialog = false;
    this.resetFormValues();
    this.cancelCreateClient();
  }

  prepareToInsert(oauthclients) {
    this.insertDialog = true;
    this.addedClient = oauthclients;
  }

  insertNewClient() {
    this.insertDialog = false;
    this.itsInfinite();
    const clientProps = {
      clientId: this.cliId,
      clientSecret: this.currentSecretId,
      name: this.cliName,
      refreshTokenValiditySeconds: this.cliRefresh,
      accessTokenValiditySeconds: this.cliAccess,
    };

    // To update the properties in edit
    clientService
      .setInsertClient(clientProps)
      .then(() => {
        this.refreshList();
        this.dialogSteps = 1;
        this.resetFormValues();

        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("oauthclients.insertedclient"),
        });
      })
      .catch((err) =>
        displayErrorMessage(err, { general: ["oauthclients.error.updateFail"] })
      );
  }

  goStep(stepNumber) {
    this.dialogSteps = stepNumber;
  }

  resetFormValues() {
    this.cliId = null;
    this.currentSecretId = null;
    this.cliName = null;
    this.cliRefresh = null;
    this.cliAccess = null;
    if (this.$refs.formStep1) this.$refs.formStep1.resetValidation();
    if (this.$refs.formStep2) this.$refs.formStep2.resetValidation();
    if (this.$refs.formStep3) this.$refs.formStep3.resetValidation();
  }

  cancelCreateClient() {
    this.dialogSteps = 1;
  }

  addToCount() {
    let numSecret = 7;
    this.result++;
    if (this.result === numSecret) {
      this.secretDialog = true;
      this.result = 0;
    }
  }

  convertToEmail() {
    clientService
      .getWacByEmail(this.cliEmail)
      .then((res) => {
        this.cliWac = res.data;
      })
      .catch((err) =>
        displayErrorMessage(err, { general: ["general.error.errorGetInfo"] })
      );
  }

  convertToWac() {
    clientService
      .getEmailByWac(this.cliWac)
      .then((res) => {
        this.cliEmail = res.data;
      })
      .catch((err) =>
        displayErrorMessage(err, { general: ["general.error.errorGetInfo"] })
      );
  }

  prepareGrantTypes(oauthclients) {
    this.currentGrantTypes = oauthclients.grantTypes.split(COMMA_SEPARATOR);
    this.currentId = oauthclients.clientId;
    this.grantTypesDialog = true;
  }

  cancelGrantTypesEdit() {
    this.grantTypesDialog = false;
  }

  editClientGrantTypes() {
    this.confirmGrantTypeDialog = false;
    this.grantTypesDialog = false;
    this.itsInfinite();
    const grantTypesProps = {
      clientId: this.currentId,
      grant: this.currentGrantTypes.filter((type) => type.length > 0),
    };

    // To update the properties in edit
    clientService
      .setGrantTypes(this.currentId, grantTypesProps)
      .then(() => {
        this.refreshList();
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("oauthclients.updatedGrantTypes"),
        });

        this.currentGrantTypes = [];
      })
      .catch((err) => {
        this.currentGrantTypes = [];

        displayErrorMessage(err, {
          general: ["oauthclients.error.updateFail"],
        });
      });
  }
}
</script>
