import { DeviceCacheReaderInterface } from "./DeviceCacheReaderInterface";

export default class DeviceCacheReaderEntity {
  public id: string;
  public family: string;
  public type: string;
  public subtype: string;

  constructor(data: DeviceCacheReaderInterface) {
    this.id = data.id;
    this.family = data.family;
    this.type = data.type;
    this.subtype = data.subtype;
  }

  /** Indica si es un Monitor. */
  public isMonitor(): boolean {
    return this.family === "MONITOR";
  }

  /** Indica si es un teléfono. */
  public isPhone(): boolean {
    return this.family === "PHONE";
  }

  /** Indica si es una consergería. */
  public isGuardUnit(): boolean {
    return this.family === "GUARDUNIT";
  }

  /** Indica si es un panel. */
  public isPanel(): boolean {
    return this.family === "PANEL";
  }

  /** Indica si es un dispositivo con wifi (WIFI o WIFI-DDA). */
  private isWifi(): boolean {
    return this.subtype === "WIFI" || this.subtype === "WIFI-DDA";
  }

  public getNameIcon(): string {
    if (this.isPhone()) {
      return "phone";
    }
    if (this.isGuardUnit()) {
      return "guardUnit";
    }
    if (this.isMonitor()) {
      if (this.isWifi()) {
        if (this.type === "VEO-XS") {
          return "monitorVeoXsWifi";
        }
        if (this.type === "VEO") {
          return "monitorVeoWifi";
        }
        if (this.type === "VEO-XL") {
          return "monitorVeoXlWifi";
        }
      } else {
        if (this.type === "VEO-XS") {
          return "monitorVeoXsNoWifi";
        }
        if (this.type === "VEO") {
          return "monitorVeoNoWifi";
        }
        if (this.type === "VEO-XL") {
          return "monitorVeoXlNoWifi";
        }
      }
    }
    if (this.isPanel()) {
      return "placa";
    }
    return "";
  }
}
