import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FirmwareFilterEntity from "./FirmwareFilterEntity";
import {
  DeviceFamilyInterface,
  DeviceTypeInterface,
} from "@/interfaces/devices";
import DeviceController from "@/domain/controllers/DeviceController";
import AppSelect from "@/components/AppSelect.vue";
import AppInputDate from "@/components/AppInputDate.vue";

@Component({
  components: {
    AppSelect,
    AppInputDate,
  },
})
export default class FirmwareFilter extends Vue {
  @Prop()
  public filter!: FirmwareFilterEntity;

  private ctrlDevices: DeviceController = new DeviceController();

  public families: DeviceFamilyInterface[] = [];
  public familiesLoading = true;

  public types: DeviceTypeInterface[] = [];
  public typesLoading = false;

  mounted() {
    this.loadFamilies();
  }

  public loadFamilies() {
    this.familiesLoading = true;
    this.ctrlDevices.getFamilies().then((res) => {
      this.families = res;
      this.familiesLoading = false;
      if (this.filter.devicefamily !== undefined) {
        this.onChangeFamily(this.filter.devicefamily as DeviceFamilyInterface);
      }
    });
  }

  onChangeFamily(family: DeviceFamilyInterface | undefined) {
    if (family === undefined) {
      this.filter.devicetype = undefined;
      this.types = [];
    } else {
      this.typesLoading = true;
      this.ctrlDevices.getTypes(family.name).then((res) => {
        this.types = res;
        if (
          !this.types.some((type) => type.name === this.filter.devicetype?.name)
        ) {
          this.filter.devicetype = undefined;
        }
        this.typesLoading = false;
      });
    }
  }

  public onFilter() {
    this.$emit("filter", this.filter);
  }

  public onClean() {
    this.filter.clear();
    this.$emit("filter", this.filter);
  }
}
