<template lang="html" src="./DetailsRoles.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppChipInfo from "@/components/AppChipInfo.vue";
import { translate } from "@/lang/i18n";

import dateMixin from "@/mixins/date.mixin";
import tableFrontMixin from "@/mixins/table.mixin/front";
import filterMixin from "@/mixins/filter.mixin";

const keyView = "DetailsRoles";
const keySearch = keyView + ".search.";
const keyFilterOptions = keyView + ".filterOptions";

const iconColumn = (title, value) => ({
  text: title,
  value: value,
  align: "center",
  export: {
    width: 90,
  },
});

@Component({
  components: {
    AppBreadcrumbs,
    AppChipInfo,
    FilterTableHeader,
    AppFilterButtons,
    AppKpiChipInfo,
    AppInputDate,
  },
  mixins: [dateMixin, tableFrontMixin, filterMixin],
  data() {
    return { role: this.$route.params.rol };
  },
})
export default class DetailsRoles extends Vue {
  $t = translate; // Context wrapper to use translations on <script> at runtime
  filterOptions!: any;
  filterOptions_!: any;
  filterContains!: Function;
  saveFilters!: Function;
  checkHasAnyActiveFilter!: Function;

  role!: string;
  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  headers = [
    {
      text: this.$t("roles.itemName"),
      align: "left",
      value: "name",
      export: {
        width: 90,
      },
    },
    iconColumn(this.$t("menu.dashboard"), "dashboard"),
    iconColumn(this.$t("menu.plans"), "plans"),
    iconColumn(this.$t("menu.installations"), "installations"),
    iconColumn(this.$t("menu.devices"), "devices"),
    iconColumn(this.$t("menu.users"), "users"),
    iconColumn(this.$t("menu.jobs"), "jobs"),
    iconColumn(this.$t("menu.firmware"), "firmware"),
    iconColumn(this.$t("menu.oauthclients"), "oauthclients"),
    iconColumn(this.$t("menu.internalUsers"), "internalUsers"),
    iconColumn(this.$t("menu.titleRoles"), "titleRoles"),
    iconColumn(this.$t("menu.titleManufactur"), "titleManufactur"),
    iconColumn(this.$t("menu.titleCall"), "titleCall"),
  ];
  filterRol = "";
  number = 6;
  loadingData = false;
  tableProps: any = {
    sortBy: ["id"],
  };

  get rolList() {
    var listRol = this.$store.getters["roles/rolesList"];
    if (listRol) {
      if (this.filterOptions_)
        listRol = listRol.filter((i) =>
          this.filterRols(i, this.filterOptions_)
        );
    }

    return listRol;
  }

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };

    return this.checkHasAnyActiveFilter(filters);
  }

  mounted() {
    this.getRoleList();
  }

  onFilterSubmit(e) {
    e.preventDefault();

    this.saveFilters();

    this.tableProps.page = 1;

    this.refreshList();
  }

  getRoleList() {
    this.$store.dispatch("roles/getRoles");
  }

  refreshList() {
    this.saveFilters();

    this.loadingData = false;
  }

  filterRols(item, filters) {
    filters.rol = this.role;

    if (filters.rol) {
      const show = this.filterContains(item.rol, filters.rol);

      if (!show) return false;
    }

    return true;
  }
}
</script>
