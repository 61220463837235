import { Vue, Component, Prop, Ref } from "vue-property-decorator";

import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxPager,
  DxPaging,
  DxExport,
  DxToolbar,
  DxItem,
  DxGrouping,
  DxMasterDetail,
  DxEditing,
} from "devextreme-vue/data-grid";
import { DxCheckBox } from "devextreme-vue";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DxButton from "devextreme-vue/button";
import { FirmwareInterface } from "@/domain/entities/FirmwareEntity";
import AppListMenu from "@/components/AppListMenu.vue";
import { MenuOptions } from "@/interfaces/dxfDataGrid";
import { ExportType } from "@/domain/Export/ExportHeaderInterface";
import ExportFirmwares from "@/ui/Export/ExportFirmwares";

@Component({
  components: {
    DxDataGrid,
    DxCheckBox,
    DxEditing,
    DxColumn,
    DxColumnChooser,
    DxPager,
    DxPaging,
    DxExport,
    DxToolbar,
    DxItem,
    DxGrouping,
    DxMasterDetail,
    DxButton,
    AppListMenu,
  },
})
export default class FirmwareTable extends Vue {
  @Prop()
  public dataFiltered!: FirmwareInterface[];

  @Prop()
  public menuOptions!: MenuOptions[];

  @Ref("dataGrid")
  private dataGrid!: DxDataGrid;

  public pageSizes = [5, 10, "all"];

  public async onExportingExcel(e) {
    e.cancel = true;
    this.onExport(ExportType.EXCEL);
  }

  public async exportToPdf() {
    this.onExport(ExportType.PDF);
  }

  private onExport(type: ExportType) {
    const exportTable = new ExportFirmwares();
    exportTable.addData(this.dataFiltered);
    exportTable.sortByDevExtreme(this.dataGrid);
    exportTable.download(type);
  }
}
