import moment from "moment";
import PhoneInfoInterface from "./PhoneInfoInterface";
export default class PhoneInfoEntity {
  public readonly phoneMobile: string;
  private os: string;
  private osVersion: string;
  public readonly appBuild: string;
  public readonly appVersion: string;
  private readonly createdDate: string;
  private readonly updatedDate: string;
  constructor(data: PhoneInfoInterface) {
    this.phoneMobile = data.phoneMobile;
    this.os = data.os;
    this.osVersion = data.osVersion;
    this.appBuild = data.appBuild;
    this.appVersion = data.appVersion;
    this.createdDate = data.createdDate;
    this.updatedDate = data.updatedDate;
  }
  public getOsName() {
    return this.os + " " + this.osVersion;
  }

  /**
   * Obtiene la fecha de creación en formato "DD/MM/YYYY".
   * Si no tiene fecha de creación, devuelve "-".
   */
  public getCreatedAtFormat(): string {
    return this.createdDate
      ? moment(this.createdDate).format("DD/MM/YYYY")
      : "-";
  }

  /**
   * Obtiene la fecha de la última actualización en formato "DD/MM/YYYY".
   * Si no tiene fecha de actualización, devuelve "-".
   */
  public getUpdatedAtFormat(): string {
    return this.updatedDate
      ? moment(this.updatedDate).format("DD/MM/YYYY")
      : "-";
  }
}
