import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";

export default class ExportClients<T> extends ExportBase<T> {
  constructor() {
    super(t("menu.oauthclients"));
    this.generateHeaders();
  }

  private generateHeaders(): void {
    // Nombre.
    this.exportTable.addHeader({
      key: "name",
      label: t("oauthclients.clientName"),
    });
    // Identificador.
    this.exportTable.addHeader({
      key: "clientId",
      label: t("oauthclients.clientID"),
    });
    // Roles.
    this.exportTable.addHeader({
      key: "roles",
      label: t("oauthclients.rol"),
    });
    // Ámbito.
    this.exportTable.addHeader({
      key: "scopes",
      label: t("oauthclients.scopes"),
    });
    // Tipos.
    this.exportTable.addHeader({
      key: "grantTypes",
      label: t("oauthclients.grantTypes"),
    });
    // Token de acceso.
    this.exportTable.addHeader({
      key: "accessTokenValiditySeconds",
      label: t("oauthclients.accessTokenTime"),
    });
    // Token de refresco.
    this.exportTable.addHeader({
      key: "refreshTokenValiditySeconds",
      label: t("oauthclients.refreshToken"),
    });
    // Habilitado
    this.exportTable.addHeader({
      key: "enable",
      label: t("oauthclients.enabled"),
      display: this.displayYesNo.bind(this),
      styles: this.stylesYesNo.bind(this),
    });
  }
}
