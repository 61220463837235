import { FirmwareModel } from "@/ui/models/FirmwareModel";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DialogUploadFwStep1 extends Vue {
  /** Firmware que se está trabajando. */
  @Prop() firmwareUpload!: FirmwareModel;

  $refs!: {
    inputFile: HTMLInputElement;
    form: HTMLFormElement;
  };

  /** Indica si el formulario es válido. */
  validForm = true;
  /** Nombre del fichero. */
  fileName = "";
  /** Texto de error customizado para el fichero. */
  fileErrorText = "";
  /** SHA */
  sha = "";

  // #region InputFile

  onClickInputFile() {
    this.$refs.inputFile.value = "";
    this.$refs.inputFile.click();
  }

  get fileRules() {
    const patterns = [
      /^([A-Z\d]*)_(\d{2})\.(\d{2})\.(\d{3})+\.tar\.bz2$/,
      /^([A-Z\d]*)_([A-Z\d]*)_(\d{2})\.(\d{2})\.(\d{3})+\.tar\.bz2$/,
      /^([A-Z\d]*)_([A-Z\d-]*)_([A-Z\d-]*)_(\d{2})\.(\d{2})\.(\d{3})+\.tar\.bz2$/,
    ];

    const fileformatErrorMessage = this.$t("firmware.fileformat");

    return [
      (v) => {
        if (v.includes("~")) {
          return this.$t("firmware.tildeErrorMessage");
        }

        for (const pattern of patterns) {
          if (pattern.test(v)) {
            return true;
          }
        }
        if (v) {
          return fileformatErrorMessage;
        }

        return true;
      },
    ];
  }

  async onChangeFile(event) {
    const file: File = event.currentTarget.files[0];
    this.fileName = file.name;
    try {
      await this.firmwareUpload.setDataByFile(file);
      this.fileErrorText = "";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.fileErrorText = error.message;
    }
  }

  // #endregion

  get shaRules() {
    return [
      (v) => !!v || this.$t("firmware.sharequired"),
      (v) => (v && v.length == 64) || this.$t("firmware.shaformat"),
    ];
  }

  get validFile() {
    return this.firmwareUpload.existsFile();
  }

  onChangeSha(event) {
    this.sha = event.toLowerCase();
    this.firmwareUpload.sha256 = this.sha;
  }

  onContinue() {
    if (
      this.validForm &&
      this.firmwareUpload.existsFile() &&
      this.firmwareUpload.existsSha()
    ) {
      if (this.$refs.inputFile.files !== null) {
        this.$emit("continue");
        this.$refs.form.reset();
      }
    }
  }

  onCancel() {
    this.$emit("cancel");
    this.$refs.form.reset();
  }
}
