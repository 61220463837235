export const EXPORT_TYPE = {
  PDF: "pdf",
  EXCEL: "excel",
};

export const PREDEFINED_STYLES = {
  SUCCESS: {
    background: "C6EFCE",
    color: "006100",
  },
  FAILED: {
    background: "FFC7CE",
    color: "9C0006",
  },
  WARNING: {
    background: "FFEB9C",
    color: "9C5700",
  },
  WAITING: {
    background: "D9E1F2",
  },
  GREY: {
    background: "EDEDED",
  },
  DARK_GREY: {
    background: "C9C9C9",
  },
};
