import { Vue, Component } from "vue-property-decorator";

import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import AppChipInfo from "@/components/AppChipInfo.vue";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";

import UsersTable from "./UsersTable/UsersTable.vue";
import UserService from "@/api/user";
import UsersFilter from "./UsersFilter/UsersFilter.vue";
import UsersFilterEntity from "./UsersFilter/UsersFilterEntity";

@Component({
  components: {
    AppBreadcrumbs,
    AppListMenu,
    AppChipInfo,
    FilterTableHeader,
    AppKpiChipInfo,
    UsersTable,
    UsersFilter,
  },
})
export default class Users extends Vue {
  public filter: UsersFilterEntity = UsersFilterEntity.getFilter();

  $refs!: {
    usersTableRef: UsersTable;
  };

  /** Número de usuario total. */
  public numUsers = 0;

  constructor() {
    super();
    this.loadUserCount();
  }

  /** Carga el número de usuarios total. */
  loadUserCount() {
    UserService.getNumberOfUsers().then((res) => {
      this.numUsers = res.data;
    });
  }

  public onFilter() {
    this.refreshTable();
  }
  public onSearch() {
    this.refreshTable();
  }

  private refreshTable() {
    this.$refs.usersTableRef.refreshTable();
  }
}
