import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Ref } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";
import { ExportEvent } from "@/domain/Export/ExportHeaderInterface";
import ExportClients from "@/ui/Export/ExportClients";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class OAuthClientsTable extends Vue {
  // eslint-disable-next-line
  @Prop() dataArray!: Array<any>;
  // eslint-disable-next-line
  @Prop() menuOptions!: Array<any>;
  @Ref("dataGrid") dataGrid!: DxfDataGrid;

  public showExport = this.$ability.can("export", "oauthclients");

  /** Título de la tabla. */
  public title: string;

  /** Columnas de la tabla. */
  public get columns(): Column[] {
    // Se obtiene como un getter para las traducciones.
    return this.getColumns();
  }

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.title = translateText("menu.oauthclients");
  }
  private getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "name",
        caption: translateText("oauthclients.clientName"),
        sortIndex: 1,
        sortOrder: "asc",
      },
      {
        dataField: "clientId",
        caption: translateText("oauthclients.clientID"),
      },
      {
        dataField: "roles",
        caption: translateText("oauthclients.rol"),
        allowSorting: false,
        cellTemplate: "multiChipRoles",
        cssClass: "whiteSpaceNormal",
      },
      {
        dataField: "scopes",
        caption: translateText("oauthclients.scopes"),
        allowSorting: false,
        cellTemplate: "multiChipScopes",
        cssClass: "whiteSpaceNormal",
      },
      {
        dataField: "grantTypes",
        caption: translateText("oauthclients.grantTypes"),
        allowSorting: false,
        cellTemplate: "multiChipGrantTypes",
        cssClass: "whiteSpaceNormal",
      },
      {
        dataField: "accessTokenValiditySeconds",
        caption: translateText("oauthclients.accessTokenTime"),
        calculateDisplayValue(rowData) {
          if (parseInt(rowData.accessTokenValiditySeconds) === 0) {
            return "∞";
          } else {
            return rowData.accessTokenValiditySeconds;
          }
        },
      },
      {
        dataField: "refreshTokenValiditySeconds",
        caption: translateText("oauthclients.refreshToken"),
        calculateDisplayValue(rowData) {
          if (parseInt(rowData.refreshTokenValiditySeconds) === 0) {
            return "∞";
          } else {
            return rowData.refreshTokenValiditySeconds;
          }
        },
      },
      {
        dataField: "enable",
        caption: translateText("oauthclients.enabled"),
        cellTemplate: "enableTick",
      },
    ];

    if (
      this.menuOptions.filter((item) => {
        return item.allowed;
      }).length > 0
    ) {
      columns.push({
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      });
    }

    return columns;
  }

  public onExport(event: ExportEvent) {
    const exportTable = new ExportClients();
    exportTable.addData(this.dataArray);
    if (event.sort) {
      exportTable.sort(event.sort.field, event.sort.order);
    }
    exportTable.download(event.type);
  }
}
