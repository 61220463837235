/* eslint-disable @typescript-eslint/no-explicit-any */
import ExportCell from "@/domain/Export/ExportCell";
import { translateText as t, translateExists as te } from "@/lang/i18n";
import ExportBase from "./ExportBase";
export default class ExportDevices<T> extends ExportBase<T> {
  constructor(title: string, idTab: number) {
    super(title);
    this.generateHeaders(idTab);
  }
  private generateHeaders(idTab: number): void {
    // Número de serie
    this.exportTable.addHeader({
      key: "serialNumber",
      label: t("device.serialNumber"),
    });
    // Estado
    this.exportTable.addHeader({
      key: "status",
      label: t("device.status"),
      display: (_value: string, item?: T) => {
        const itemT = item as any;
        const status = itemT.status;
        const deployed = itemT.deployed;
        if (deployed) {
          if (status) {
            return t("general.online");
          } else {
            return t("general.offline");
          }
        } else {
          return t("general.manufactured");
        }
      },
      styles: (_value: string, cell: ExportCell, item?: T) => {
        const itemT = item as any;
        const status = itemT.status;
        const deployed = itemT.deployed;

        if (deployed) {
          cell.setColor(this.colorWhite);
          if (status) {
            cell.setBgColor(this.colorOk);
          } else {
            cell.setBgColor(this.colorDisconnected);
          }
        } else {
          cell.setBgColor(this.colorGray);
        }
      },
    });
    if (idTab === 6) {
      // Familia
      this.exportTable.addHeader({
        key: "family",
        label: t("device.family"),
        display: (value: string) => {
          return t("device.deviceDetails." + value);
        },
      });
    }
    // Tipo
    this.exportTable.addHeader({
      key: "typeAndSubtype",
      label: t("device.type"),
    });
    // Fecha de fabricación
    this.exportTable.addHeader({
      key: "manufacturingDate",
      label: t("device.productionDate"),
      display: (value: string) => {
        return this.dateFormatUnix(Number(value));
      },
    });
    // Fecha de activación
    this.exportTable.addHeader({
      key: "lastActivityDate",
      label: t("device.lastActivityDate"),
      display: (value: string) => {
        return this.dateFormatUnix(Number(value));
      },
    });
    // Fecha de última telemetría
    this.exportTable.addHeader({
      key: "lastTelemetryDate",
      label: t("device.lastTelemetryDate"),
      display: (value: string) => {
        return this.dateFormatUnix(Number(value));
      },
    });

    this.exportTable.addHeader({
      key: "versionHW",
      label: t("general.hardware"),
    });
    this.exportTable.addHeader({
      key: "version",
      label: t("general.version"),
    });
    this.exportTable.addHeader({
      key: "ol",
      label: t("device.ol"),
    });
    if (idTab === 4 || idTab === 5) {
      // Id de la instalación
      this.exportTable.addHeader({
        key: "installationId",
        label: t("deviceDetails.installation"),
      });
      // Señal
      this.exportTable.addHeader({
        key: "wirelessSignal",
        label: t("device.properties.signal_wifi"),
      });
      this.exportTable.addHeader({
        key: "iccid",
        label: t("deviceDetails.iccid"),
      });
      this.exportTable.addHeader({
        key: "imei",
        label: t("deviceDetails.imei"),
      });
      this.exportTable.addHeader({
        key: "simStatus",
        label: t("deviceDetails.simStatus"),
        display: (value: string) => {
          const key = "deviceDetails.status." + value;
          return te(key) ? t(key) : "";
        },
      });
      this.exportTable.addHeader({
        key: "simDateUpdated",
        label: t("deviceDetails.simDate"),
        display: (value: string) => {
          return this.dateFormat(Number(value));
        },
      });
    }

    this.exportTable.addHeader({
      key: "zone",
      label: t("geo.zone"),
    });
    this.exportTable.addHeader({
      key: "subzone",
      label: t("geo.subzone"),
    });
    this.exportTable.addHeader({
      key: "area",
      label: t("geo.area"),
    });
  }
}
