import { Vue, Component, Prop } from "vue-property-decorator";

enum PairingStatusEnum {
  INPROGRESS = "inprogress",
  PAIRED = "paired",
  DISABLED = "disabled",
  UPDATING = "updating",
}

@Component
export default class PairingStatus extends Vue {
  @Prop({ type: String, default: PairingStatusEnum.DISABLED })
  status!: PairingStatusEnum;

  getStatusClass() {
    if (this.isInProgress()) {
      return "inprogress";
    }
    if (this.isPaired()) {
      return "paired";
    }
    if (this.isDisabled()) {
      return "disabled";
    }
    if (this.isUpdating()) {
      return "updating";
    }
  }

  getStatusText() {
    if (this.isInProgress()) {
      return this.$t("user.pairingStatus.inprogress");
    }
    if (this.isPaired()) {
      return this.$t("user.pairingStatus.paired");
    }
    if (this.isDisabled()) {
      return this.$t("user.pairingStatus.disabled");
    }
    if (this.isUpdating()) {
      return this.$t("user.pairingStatus.updating");
    }
  }

  private isInProgress() {
    return this.status.toLowerCase() === PairingStatusEnum.INPROGRESS;
  }
  private isPaired() {
    return this.status.toLowerCase() === PairingStatusEnum.PAIRED;
  }
  private isDisabled() {
    return this.status.toLowerCase() === PairingStatusEnum.DISABLED;
  }
  private isUpdating() {
    return this.status.toLowerCase() === PairingStatusEnum.UPDATING;
  }
}
