import pdfMake from "pdfmake/build/pdfmake";

import i18n from "@/lang/i18n";
import moment from "moment";

import { dateFormat } from "@/mixins/date.mixin";
import { imageToBase64 } from "@/mixins/table-export.mixin/exporters/pdf.exporter";

import JobEntity from "@/domain/entities/JobEntity";
import JobModel from "@/ui/models/JobModel";

const pdfName = (data) =>
  (i18n.t("jobdetails.job") + "-" + data.jobName)
    .toLowerCase()
    .replace(/ /g, "-") + ".pdf";

// PDF table styles
pdfMake.tableLayouts = {
  fwDetailsLayout: {
    hLineWidth: () => 0.25,
    vLineWidth: () => 0.25,
    hLineColor: () => "#BBB",
    vLineColor: () => "#BBB",
    paddingLeft: () => 10,
    paddingRight: () => 10,
    paddingTop: () => 10,
    paddingBottom: () => 10,
  },
  deviceListLayout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    hLineColor: () => null,
    paddingLeft: () => 5,
    paddingRight: () => 5,
    paddingTop: () => 5,
    paddingBottom: () => 5,
    fillColor: (i) => {
      return i !== 0 ? ((i - 1) % 2 === 0 ? "#eee" : "#fff") : null;
    },
  },
};

/**
 * Obtiene las imágenes necesarias para el documento.
 * @param {String} imgUrlDevice - URL de la imagen del dispositivo.
 * @returns {Object} - Objeto con las tres imágenes.
 * @returns {String} - Objeto.chart - Imagen del gráfico de tarta.
 * @returns {String} - Objeto.logo - Imagen del logo.
 * @returns {String} - Objeto.device - Imagen del dispositivo.
 * @async
 * @private
 * @function
 * @name getDocImages
 * @example
 * const docImages = await getDocImages("http://example.com/image.jpg");
 */
async function getDocImages(imgUrlDevice) {
  // Objeto con las tres imágenes.
  const docImages = {
    chart: "",
    logo: "",
    device: "",
  };
  // Dirección de la imagen del logo.
  const imageUrlLogo = process.env.VUE_APP_PUBLIC_PATH + "assets/blue.png";
  // Dirección de la imagen del dispositivo.
  const imageUrlDevice = imgUrlDevice;
  // Dirección de la imagen si el dispositivo no exite.
  const imageUrlUnknown =
    process.env.VUE_APP_PUBLIC_PATH + "assets/devices/unknown.jpg";

  // Se obtiene el elemento del gráfico de tarta.
  const chartEl = document.querySelector("#pie-chart");
  // Si existe la gáfica, se convierte a base64.
  if (chartEl) docImages.chart = chartEl.toDataURL("image/png");

  // Se convierte la imagen del logo a base64.
  docImages.logo = await imageToBase64(imageUrlLogo, "png");

  // Se comprueba si la imagen del dispositivo existe.
  const response = await fetch(imageUrlDevice, {
    method: "HEAD",
    redirect: "manual",
  });
  const type = response.headers.get("content-type");
  if (type && type.startsWith("image")) {
    // Si existe, se convierte a base64.
    docImages.device = await imageToBase64(imageUrlDevice, "png");
  } else {
    // Si no existe, se convierte la imagen del dispositivo desconocido a base64.
    docImages.device = await imageToBase64(imageUrlUnknown, "png");
  }
  // Se devuelven las imágenes.
  return docImages;
}

export default async (jobData, jobInfo) => {
  const docImages = await getDocImages(jobData.imgURL);
  pdfMakeCreatePdfDownload(jobData, jobInfo, docImages);
};

function pdfMakeCreatePdfDownload(jobData, jobInfo, docImages) {
  // Variables and functions for PDF definition
  const title = i18n.t("jobdetails.jobid") + ": " + jobData.jobName;

  const jobEntity = new JobEntity({ status: jobData.status });
  const jobModel = new JobModel(jobEntity);

  const createSpanner = (spanTitle, value) => {
    return {
      margin: [0, 5],
      stack: [
        { text: spanTitle, fontSize: 10 },
        { text: value, margin: [0, 7], fontSize: 12, color: "#9e9e9e" },
      ],
    };
  };

  const format = "DD/MM/YYYY HH:mm:ss";
  const createdDate = dateFormat(jobData.createdDate, format);
  const startDate = moment.utc(jobData.dateToStart).format(format);
  const endDate = dateFormat(jobData.endDate, format);

  const version =
    jobData.firmware.family +
    " " +
    jobData.firmware.type +
    " " +
    jobData.firmware.subtype +
    " " +
    jobData.firmware.versionHW +
    "." +
    jobData.firmware.versionSW;
  const build = jobData.firmware.build || "--";
  const changelog = jobData.firmware.changelog || "--";
  const fwDetails = jobData.firmware.details || "--";

  const createDeviceListTable = (type) => {
    const tableTitle = i18n
      .t("jobs.deviceStatus." + type.toLowerCase())
      .toUpperCase();

    const items = [];

    for (let key in jobData.results) {
      const item = jobData.results[key];

      if (item.summaryStatus === type)
        items.push([
          { text: key, fontSize: 8 },
          { text: item.status, fontSize: 8 },
          { text: item.description, fontSize: 8 },
        ]);
    }

    const theadStatusColors = {
      SUCCESS: [76, 175, 80],
      FAILED: [244, 67, 54],
      RUNNING: [33, 150, 243],
      CANCELLED: [97, 97, 97],
    };

    return {
      margin: [0, 5, 0, 10],
      stack: [
        {
          margin: [0, 0, 0, 5],
          text: tableTitle + " - " + items.length,
        },
        {
          layout: "deviceListLayout",
          table: {
            widths: [180, 100, 200],
            body: [
              [
                {
                  text: i18n.t("formLabels.name"),
                  fontSize: 8,
                  color: "#FFF",
                  fillColor: theadStatusColors[type],
                  bold: true,
                },
                {
                  text: i18n.t("formLabels.status"),
                  fontSize: 8,
                  color: "#FFF",
                  fillColor: theadStatusColors[type],
                  bold: true,
                },
                {
                  text: i18n.t("formLabels.description"),
                  fontSize: 8,
                  color: "#FFF",
                  fillColor: theadStatusColors[type],
                  bold: true,
                },
              ],
              ...items,
            ],
          },
        },
      ],
    };
  };

  const othersDeviceListTables = [];
  if (jobInfo.numRunningDevices > 0)
    othersDeviceListTables.push(createDeviceListTable("RUNNING"));
  if (jobInfo.numCancelledDevices > 0)
    othersDeviceListTables.push(createDeviceListTable("CANCELLED"));

  // Create PDF definition
  const docDefinition = {
    images: docImages,
    styles: {
      header: {
        fontSize: 12,
        margin: [0, -30, 0, 10],
      },
    },
    content: [
      {
        stack: [
          {
            columns: [
              { image: "logo", width: 50 },
              { text: title, fontSize: 14, margin: [5, 10, 0, 0] },
              {
                alignment: "right",
                width: "70",
                margin: [0, 10],
                layout: "noBorders",
                table: {
                  widths: [70],
                  body: [
                    [
                      {
                        text: jobModel.getStatusText(),
                        color: "#fff",
                        fontSize: 10,
                        fillColor: jobModel.getStatusColorPdf(),
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
        style: "header",
      },
      {
        margin: [0, 10],
        columns: [
          { image: "device", width: 120 },
          {
            margin: [25, 10],
            stack: [
              createSpanner(
                i18n.t("deviceDetails.device"),
                jobData.firmware.family +
                  " " +
                  jobData.firmware.type +
                  " " +
                  jobData.firmware.subtype
              ),
              createSpanner(i18n.t("jobs.jobcreatedtime"), createdDate),
              createSpanner(i18n.t("jobs.jobendtime"), endDate || "--"),
            ],
          },
          {
            margin: [25, 10],
            stack: [
              createSpanner(
                i18n.t("jobdetails.hwVersion"),
                jobData.firmware.versionHW
              ),
              createSpanner(
                i18n.t("jobs.jobstarttime"),
                startDate + i18n.t("general.utc")
              ),
            ],
          },
        ],
      },
      {
        margin: [0, 25, 0, 15],
        text: i18n.t("jobdetails.fwDetails"),
        bold: true,
      },
      {
        layout: "fwDetailsLayout",
        table: {
          widths: [235, 235],
          body: [
            [
              {
                text: i18n.t("jobdetails.version"),
                fontSize: 9,
                fillColor: "#DDD",
                bold: true,
              },
              {
                text: i18n.t("device.build"),
                fontSize: 9,
                fillColor: "#DDD",
                bold: true,
              },
            ],
            [
              { text: version, fontSize: 8 },
              { text: build, fontSize: 8 },
            ],
            [
              {
                text: i18n.t("firmware.changelog"),
                fontSize: 9,
                fillColor: "#DDD",
                bold: true,
              },
              {
                text: i18n.t("firmware.details"),
                fontSize: 9,
                fillColor: "#DDD",
                bold: true,
              },
            ],
            [
              { text: changelog, fontSize: 8 },
              { text: fwDetails, fontSize: 8 },
            ],
          ],
        },
      },
      {
        margin: [0, 30, 0, 15],
        text: i18n.t("jobdetails.statusSummary"),
        bold: true,
      },
      {
        image: "chart",
        width: 250,
      },
      {
        pageBreak: "before",
        margin: [0, 0, 0, 15],
        text: i18n.t("jobdetails.deviceDetails"),
        bold: true,
      },
      createDeviceListTable("SUCCESS"),
      createDeviceListTable("FAILED"),
      ...othersDeviceListTables,
    ],
    footer: function (currentPage, pageCount) {
      return {
        text: `${i18n.t(
          "general.messages.page"
        )} ${currentPage.toString()} ${i18n.t(
          "general.messages.conjunctionOf"
        )} ${pageCount}`,
        margin: [40, 10, 0, 0],
        fontSize: 10,
      };
    },
  };
  pdfMake.createPdf(docDefinition).download(pdfName(jobData));
}
