/* eslint-disable */
import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";
import CustomStore from "devextreme/data/custom_store";

import UserService from "@/api/user";
import { LoadResultObject } from "devextreme/common/data/custom-store";

import LanguagesHelper from "../../../helpers/languagesHelper";
import * as i18nIsoCountries from "i18n-iso-countries";
import i18n from "@/lang/i18n";
import DateHelper from "@/helpers/dateHelper";
import ExportUsers from "@/ui/Export/ExportUsers";
import { ExportEvent } from "@/domain/Export/ExportHeaderInterface";
import UsersFilterEntity from "../UsersFilter/UsersFilterEntity";
import { LoadOptions } from "devextreme/data";
import UserController from "@/domain/users/UserController";

export interface TablePagination {
  rowsPerPage: number;
  page: number;
  descending: boolean;
}

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class UsersTable extends Vue {
  @Prop()
  public filter!: UsersFilterEntity;

  public title = translateText("menu.users");

  private ctrl = new UserController();

  public showExport = this.$ability.can("export", "users");

  /** Conexión para obtener los datos de la tabla. */
  public dataStore: CustomStore;

  /** Columnas de la tabla. */
  public get columns(): Column[] {
    // Se obtiene como un getter para las traducciones.
    return this.getColumns();
  }

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.dataStore = this.getNewCustomStore();
  }

  private getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "email",
        caption: translateText("user.email"),
        cellTemplate: this.$ability.can("details", "users")
          ? "linkDetail"
          : "notLinkDetail",
      },
      {
        dataField: "locale",
        caption: translateText("menu.language"),
        calculateDisplayValue: (rowData: any) => {
          return LanguagesHelper.getLanguageName(rowData.locale.toLowerCase());
        },
        allowSorting: false,
      },
      {
        dataField: "country",
        caption: translateText("general.country"),
        calculateDisplayValue: (rowData: any) => {
          return i18nIsoCountries.getName(rowData.country, i18n.locale);
        },
        calculateSortValue: () => "country",
      },
      {
        dataField: "createdAt",
        caption: translateText("user.eDate"),
        calculateDisplayValue: (rowData: any) => {
          return DateHelper.dateFormat(
            rowData.createdAt,
            "DD/MM/YYYY HH:mm:ss"
          );
        },
        calculateSortValue: () => "createdAt",
      },
      {
        dataField: "pairingType",
        caption: translateText("user.device"),
        customizeText: (cellInfo) => {
          if (cellInfo.valueText === "BOTH") {
            return translateText("user.deviceType.both");
          } else if (cellInfo.valueText === "WIFI") {
            return translateText("user.deviceType.wifi");
          } else if (cellInfo.valueText === "NO_WIFI") {
            return translateText("user.deviceType.nowifi");
          } else {
            return translateText("user.deviceType.none");
          }
        },
        allowSorting: false,
      },
      {
        dataField: "activePremiumSubscription",
        caption: translateText("user.payment"),
        cellTemplate: "tickCheckCancel",
        width: 70,
      },
      {
        dataField: "provider",
        caption: translateText("user.provider"),
        calculateDisplayValue(rowData) {
          const provider = rowData.provider;
          if (provider === "BLUE") {
            return "Blue";
          }
          if (provider === "OPENDIT") {
            return "Opendit";
          }
          return provider;
        },
        calculateSortValue: () => "provider",
      },
      {
        dataField: "acceptPrivacy",
        caption: translateText("user.privacy"),
        cellTemplate: "cellAppChipInfoUser",
      },
      {
        dataField: "acceptSharing",
        caption: translateText("user.data"),
        cellTemplate: "cellAppChipInfoUser",
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    return columns;
  }

  public get menuOptions() {
    return [
      {
        icon: "people",
        text: this.$t("user.userDetails"),
        to: (item) => ({ name: "UserDetails", params: { email: item.email } }),
        allowed: this.$ability.can("details", "users"),
      },
      {
        icon: "cloud_download",
        text: this.$t("user.export"),
        action: (item) => this.exportData(item.email),
        allowed: this.$ability.can("exportDetails", "users"),
      },
    ];
  }

  private exportData(email) {
    UserService.getUserId(email)
      .then((userRes) => {
        const userId = userRes.data;
        UserService.exportData(userId, email).catch((err) => {
          console.error(err);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /** Obtiene el objeto store y hace un load de los datos. */
  private getNewCustomStore(): CustomStore {
    let loadedUser: any[] = [];

    return new CustomStore({
      key: "email",
      load: async (loadOptions: LoadOptions) => {
        this.filter.setParamsByLoadOptions(loadOptions);

        const res = await this.ctrl.getUsers(this.filter);

        loadedUser = res.content;

        loadedUser.map((user) => {
          user.to = {
            name: "UserDetails",
            params: { email: user.email },
          };
        });

        const result: LoadResultObject = {
          data: loadedUser,
          totalCount: res.totalElements,
        };

        return result;
      },
    });
  }

  public async onExport(event: ExportEvent) {
    const data = await this.ctrl.getUsersRecursively(this.filter);
    const exportTable = new ExportUsers();
    exportTable.addData(data);
    exportTable.download(event.type);
  }

  public refreshTable() {
    this.dataStore = this.getNewCustomStore();
  }
}
