<template lang="html" src="./InstallationDetails.html"></template>
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import dateMixin from "@/mixins/date.mixin";
import detailsMixin from "@/mixins/details.mixin";

import AppListMenu from "@/components/AppListMenu.vue";
import AppEventList from "@/components/AppEventList.vue";

import i18n from "@/lang/i18n";
import { displayErrorMessage } from "@/api";
import installationService, { FAMILY } from "@/api/installation";
import deviceService from "@/api/device";
import rexistroService from "@/api/rexistro";
import { goTo } from "@/router";

import InstallationStatusChip from "../InstallationStatusChip/InstallationStatusChip.vue";
import InstallationDetailsMonitor from "./InstallationDetailsMonitor/InstallationDetailsMonitor.vue";
import InstallationSummary from "../InstallationSummary/InstallationSummary.vue";

import JobCreateModal from "@/views/Jobs/JobCreateModal/JobCreateModal.vue";

import { getMarkerIcon } from "../Installations.vue";
import MarkerMap from "@/components/MarkerMap/MarkerMap.vue";
import FullScreenMap from "@/components/FullScreenMap/FullScreenMap.vue";
import DeviceProperties from "../../Devices/DeviceProperties/DeviceProperties.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import moment from "moment";

import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxHeaderFilter,
  DxScrolling,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxExport,
  DxToolbar,
  DxItem,
  DxOperationDescriptions,
  DxStateStoring,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import topology from "../../../entities/topology";
import "jspdf-autotable";
import DxButton from "devextreme-vue/button";

import InstallationDetailsTable from "./InstallationDetailsTable/InstallationDetailsTable.vue";

const PHONE_FAMILY = "PHONE";
const GUARD_FAMILY = "GUARDUNIT";
const BLOCK_EG = 100;
const dataGridRef = "dataGrid";

import TooltipAndDateTime from "@/ui/components/TooltipAndDateTime/TooltipAndDateTime.vue";

import InstallationPenetrationDialog from "./InstallationPenetrationDialog/InstallationPenetrationDialog.vue";
import InstallationDetailsUsersTable from "./InstallationDetailsUsersTable/InstallationDetailsUsersTable.vue";
import InstallationController from "@/domain/installation/InstallationController";

@Component({
  components: {
    AppBreadcrumbs,
    InstallationStatusChip,
    InstallationDetailsMonitor,
    InstallationSummary,
    MarkerMap,
    FullScreenMap,
    JobCreateModal,
    AppListMenu,
    AppEventList,
    DxDataGrid,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxHeaderFilter,
    DxScrolling,
    DxPager,
    DxPaging,
    DxFilterRow,
    DxExport,
    DeviceProperties,
    AppInputDate,
    DxButton,
    DxToolbar,
    DxItem,
    DxOperationDescriptions,
    DxStateStoring,
    InstallationDetailsTable,
    TooltipAndDateTime,
    InstallationPenetrationDialog,
    InstallationDetailsUsersTable,
  },
  mixins: [dateMixin, detailsMixin],
  data() {
    return {
      // FIXME: $route is not read inside the class in the assignment
      installationId: this.$route.params.installationId,
      idTab: this.$route.params.idTab,
      dataGridRef,
    };
  },
})
export default class InstallationDetails extends Vue {
  public currentTab = 0;
  public loadingTopology = true;
  public dataTopology: Array<topology> = [];

  $vuetify!: any;
  checkItemNotFound!: Function;

  installationId!: string;
  topologySelectedItems: any = [];
  confirmForgetDialogShow = false;
  installationTag: string | null = null;
  showPenetration = false;
  numberTotalUnits = null;
  percentPenetration = "-";
  numberDevicesWithActiveSubscription = null;
  refreshPenetration = false;
  tagEditMode = false;
  tagEditIsSaving = false;
  lastTopologyOpenedItems: Array<any> = [];
  topologyOpenedItems: Array<any> = [`b_${BLOCK_EG}`];
  expandOrCollapseOperationActive = false;
  topologySearch = "";
  TAG_MAX_CHARACTERS = 32;
  fullScreenMarkerMap = false;
  updateVersionDialog = false;
  installationMap: Array<any> = [];
  selectedDevices: Array<any> = [];
  panelSubblock: Array<any> = [];
  monitorSubblock: Array<any> = [];
  guardSubblock: Array<any> = [];
  showConfirmRefreshInstallation = false;
  showNotifyRefresh = false;
  isRefreshingInstallation = false;
  showEventHistory = false;
  isLoadingEvents = false;
  eventsHistory = Array<{
    time: number;
    deviceId: string;
    type: string;
    subtype: string;
  }>();
  totalPagesEvent = 0;
  totalSizeEvent = 0;
  pageNumberEvent = 1;
  $moment!: any;
  endDateHistory = moment().format("YYYY-MM-DD");
  startDateHistory = moment().subtract(30, "days").format("YYYY-MM-DD");
  deviceId!: string;
  showDeviceProperties = false;
  deviceSerialNumber = "";

  $refs!: {
    topology: any;
  };

  mounted() {
    this.loadData();
  }

  private loadData() {
    this.loadTopology();
  }

  private async loadTopology() {
    this.loadingTopology = true;
    let topologyItems: Array<topology> = [];

    const ctrl = new InstallationController();
    const topology = await ctrl.getTopology(this.installationId);

    if (topology && topology.blocks) {
      for (const block of topology.blocks) {
        const numBlock = block.num;
        if (block.ediboxes.length > 0) {
          for (const edibox of block.ediboxes) {
            topologyItems.push(this.buildItemTopology(numBlock, edibox));
          }
        }
        if (block.gu.length > 0) {
          for (const gu of block.gu) {
            topologyItems.push(this.buildItemTopology(numBlock, gu));
          }
        }
        if (block.panels.length > 0) {
          for (const panel of block.panels) {
            topologyItems.push(this.buildItemTopology(numBlock, panel));
          }
        }
        if (block.subblocks.length > 0) {
          for (const subblock of block.subblocks) {
            this.processSubblocks(numBlock, subblock, topologyItems);
          }
        }
        if (block.units.length > 0) {
          for (const unit of block.units) {
            for (const device of unit.devices) {
              topologyItems.push(this.buildItemTopology(numBlock, device));
            }
          }
        }
      }
    }
    // Ordenar por bloque
    topologyItems.sort((a, b) => b.bloque - a.bloque);

    this.loadingTopology = false;

    this.dataTopology = topologyItems;
  }

  get details() {
    return this.$store.getters["installations/installationDetails"];
  }

  get menuOptions() {
    return [
      {
        icon: "system_update",
        text: this.$t("installationDetails.updateFirmwares"),
        action: () => this.showUpdateVersionDialog(true),
        allowed:
          this.details.status !== "DISCONNECTED" &&
          this.dataTopology.length > 0 &&
          this.$ability.can("update", "installations"),
      },
      {
        icon: "refresh",
        text: this.$t("installationDetails.refresh"),
        action: () => this.refreshView(),
        allowed: this.$ability.can("refresh", "installations"),
      },
      {
        icon: "delete_sweep",
        text: this.$t("installationDetails.forget"),
        action: () => this.showConfirmForgetDialog(true),
        allowed:
          this.$ability.can("forget", "installations") &&
          this.forgetOff(this.dataTopology),
      },
      {
        icon: "sync",
        text: this.$t("installationDetails.refreshPanels"),
        action: () => this.setShowConfirmRefreshInstallation(true),
        allowed:
          this.details.status !== "DISCONNECTED" &&
          this.$ability.can("refresh", "installations") &&
          this.details?.deviceMaster?.family === "PANEL",
      },
      {
        icon: "reduce_capacity",
        text: this.$t("installation.penetration"),
        action: () => this.showConfirmPenetration(),
        allowed:
          this.$ability.can("refresh", "installations") &&
          this.details?.deviceMaster?.family !== "EDIBOX",
      },
      {
        icon: "open_in_new",
        text: this.$t("installationDetails.viewInMyConnect"),
        action: this.openInMyConnect,
        allowed: true,
      },
      /*   {
        icon: "fa-clipboard-list",
        iconProps: { size: 20, style: { "margin-left": "2px" } },
        text: this.$t("general.eventHistory"),
        action: () => this.openEventHistory(),
        allowed:
          this.$ability.can('eventHistory','installations')
      } */
    ];
  }

  /** Abre una nueva ventana con la instalación en MyConnect */
  openInMyConnect() {
    const URI_MYCONNECT =
      this.$store.getters["getUriMyConnect"]?.split(",") ||
      process.env.VUE_APP_PRE_URI_MYCONNECT;
    const url = URI_MYCONNECT + "/installation-details/" + this.installationId;
    window.open(url, "_blank");
  }

  get menuOptionsTopology() {
    return [
      {
        icon: "flip_to_front",
        text: this.$t("installationDetails.moreInfo"),
        to: (item) => ({
          name: "DeviceDetails",
          params: { deviceId: item.id },
        }),
        allowed: this.$ability.can("details", "devices"),
      },
      {
        icon: "dns",
        text: this.$t("deviceDetails.showProperties"),
        action: (item) => this.showProperties(item),
        allowed: this.$ability.can("details", "devices"),
      },
      {
        icon: "fa-clipboard-list",
        iconProps: { size: 20, style: { "margin-left": "2px" } },
        text: this.$t("general.eventHistory"),
        action: (item) => this.openEventHistoryDevice(item),
        allowed: this.$ability.can("eventHistory", "installations"),
      },
    ];
  }

  processSubblocks(
    numBlock: number,
    item: any,
    topologyItems: Array<topology>
  ) {
    if (item.ediboxes.length > 0) {
      for (const edibox of item.ediboxes) {
        topologyItems.push(this.buildItemTopology(numBlock, edibox));
      }
    }
    if (item.gu.length > 0) {
      for (const gu of item.gu) {
        topologyItems.push(this.buildItemTopology(numBlock, gu));
      }
    }
    if (item.panels.length > 0) {
      for (const panel of item.panels) {
        topologyItems.push(this.buildItemTopology(numBlock, panel));
      }
    }
    if (item.units.length > 0) {
      for (const unit of item.units) {
        for (const device of unit.devices) {
          topologyItems.push(this.buildItemTopology(numBlock, device));
        }
      }
    }
  }

  buildItemTopology(numBlock: number, item: any): topology {
    const itemTopology = new topology();
    itemTopology.bloque = numBlock;
    itemTopology.name =
      item.family != "PANEL" && item.family != "GUARDUNIT"
        ? `Vivienda ${item.unitNumber}`
        : "";
    itemTopology.id = item.serialNumber;
    itemTopology.direccionBloque = item.numBlock;
    if (item.numSubblock === -1) {
      itemTopology.direccionSubBloque = "-";
    } else {
      itemTopology.direccionSubBloque = item.numSubblock;
    }
    itemTopology.direccionVivienda = item.unitNumber;
    itemTopology.device = item.family + " " + item.subtype;
    itemTopology.model = item.type;
    //itemTopology.estate = item.status ? "CONNECTED" : "DISCONNECTED";
    itemTopology.estate = item.status;
    itemTopology.deployed = item.deployed;

    /** Firmware versión 2 */
    const fwvv2 = item.fwVersionV2;
    if (fwvv2 === null) {
      // Si no tiene se utiliza el formato antiguo.
      itemTopology.version = item.versionHW + "." + item.versionSW;
      itemTopology.build = item.build;
    } else {
      itemTopology.version = fwvv2.fwMajor + "." + fwvv2.fwMinor;
      itemTopology.build = fwvv2.fwBuild;
    }
    itemTopology.versionHW = item.versionHW;
    //itemTopology.callDivertServiceEnabled = item.callDivertServiceEnabled === null ? "" : item.callDivertServiceEnabled ? "Si" : "No"
    itemTopology.callDivertServiceEnabled =
      this.callDivertServiceEnabledForWifi(item);
    itemTopology.coverage =
      item.coverage != null ? this.coverageByType(item) : "";

    //TODO: para poder generar los device para la actualizacion de FW
    const deviceForFamily = {
      family: item.family,
      type: item.type,
      subtype: item.subtype,
      devices: [item],
    };

    const existingDeviceIndex = this.installationMap.findIndex(
      (device) =>
        device.family === item.family &&
        device.type === item.type &&
        device.subtype === item.subtype
    );

    if (existingDeviceIndex > -1) {
      this.installationMap[existingDeviceIndex].devices.push(item);
    } else {
      this.installationMap.push(deviceForFamily);
    }

    return itemTopology;
  }

  coverageByType(item) {
    if (item.family === "MONITOR") {
      switch (item.coverage) {
        case 0:
          return "0 %";
        case 1:
          return "25 %";
        case 2:
          return "50 %";
        case 3:
          return "75 %";
        case 4:
          return "100 %";
        default:
          return "";
      }
    } else {
      return item.coverage + " %";
    }
  }

  callDivertServiceEnabledForWifi(item) {
    if (
      item.subtype === "WIFI-DDA" ||
      item.subtype === "WIFI" ||
      item.family === "PANEL"
    ) {
      return null;
    } else {
      return item.callDivertServiceEnabled;
    }
  }

  forgetOff(items) {
    return !items.some((item) => item.callDivertServiceEnabled);
  }
  goToDetails(item: any) {
    //if (this.closeModal) this.closeModal();
    goTo(`device-${item.id}`);
  }

  showProperties(item: any) {
    this.showDeviceProperties = true;
    this.deviceSerialNumber = item.id;
  }

  get filteredTree() {
    const items = this.filterTree(this.dataTopology, this.topologySearch) || [];

    return items;
  }

  get topologyBlocks() {
    const blocksNumber = (three) =>
      three
        .map((block) => {
          let count = 0;

          if (block.children && block.children.length > 0) {
            count += 1 + blocksNumber(block.children);
          }

          return count;
        })
        .reduce((a, b) => a + b);

    return blocksNumber(this.filteredTree);
  }

  // Obtain object of selected item.
  get topologySelectedItem() {
    let selectedItem = null;

    // Find selected item easily, by iterating all topology json object passed.
    const findItemNested = (arr, attribute, value, nestingKey) =>
      arr.reduce((a, item) => {
        if (a) return a; // return selected item if was found previously
        if (item[attribute] === value) return item; // return selected item
        if (item[nestingKey])
          // iterate array of children (if the object has it) to find the item
          return findItemNested(item[nestingKey], attribute, value, nestingKey);
      }, null);

    if (this.topologySelectedItems && this.topologySelectedItems.length > 0)
      selectedItem = findItemNested(
        this.dataTopology,
        "serialNumber",
        this.topologySelectedItems[0],
        "children"
      );

    return selectedItem;
  }

  get topologyDetailsModalShow() {
    return this.$vuetify.breakpoint.xsOnly && this.topologySelectedItem;
  }

  set topologyDetailsModalShow(v) {
    if (!v) this.topologySelectedItems = [];
  }

  @Watch("details")
  onChangeDetails() {
    if (this.details) this.installationTag = this.details.tag;
  }

  @Watch("topologyOpenedItems")
  onChangeTopologyOpenedItems() {
    if (
      !this.expandOrCollapseOperationActive && // not execute when click on expand/collapse options
      this.lastTopologyOpenedItems.length < this.topologyOpenedItems.length && // is opened a new item
      this.lastTopologyOpenedItems.length > 0 && // there was opened items before
      this.topologyOpenedItems[this.topologyOpenedItems.length - 1].startsWith(
        "b_"
      ) // new opened item is a block
    ) {
      this.topologyOpenedItems = [
        this.topologyOpenedItems[this.topologyOpenedItems.length - 1],
      ]; // open just this
    } else this.expandOrCollapseOperationActive = false;

    this.lastTopologyOpenedItems = [...this.topologyOpenedItems]; // save history
  }

  beforeMount() {
    this.obtainInformation();
  }

  setShowConfirmRefreshInstallation(v) {
    this.showConfirmRefreshInstallation = v;
  }

  setShowNotifyRefresh(v) {
    this.showNotifyRefresh = v;
  }

  public visiblePenetrationDialog = false;

  showConfirmPenetration() {
    this.visiblePenetrationDialog = true;
  }

  getMarkerIcon = getMarkerIcon;

  showUpdateVersionDialog(show) {
    this.updateVersionDialog = show;
  }

  showConfirmForgetDialog(show) {
    this.confirmForgetDialogShow = show;
  }

  getDetails() {
    return this.$store
      .dispatch("installations/getInstallationDetails", {
        id: this.installationId,
      })
      .catch((e) => this.checkItemNotFound(e, "installations"));
  }

  obtainInformation() {
    this.installationMap = [];
    return Promise.all([this.getDetails()]);
  }

  refreshView() {
    this.loadData();
    installationService
      .refreshInstallation(this.installationId)
      .then(() => {
        this.obtainInformation().then(() => {
          this.$store.dispatch("snackbarInfo", {
            text: i18n.t("installationDetails.refreshedInfo"),
          });
        });
      })
      .catch((err) =>
        displayErrorMessage(err, {
          general: ["installationDetails.error.getError"],
        })
      );
  }

  onClickTagEditSubmit(e) {
    e.preventDefault();
    this.onClickTagEdit();
  }

  onClickTagEdit() {
    if (this.tagEditMode) {
      if (
        this.installationTag &&
        this.installationTag.trim() !== "" &&
        this.installationTag.length <= this.TAG_MAX_CHARACTERS
      ) {
        if (this.installationTag !== this.details.tag) {
          this.tagEditIsSaving = true;

          installationService
            .updateInstallationTag(
              this.installationId,
              this.installationTag.trim()
            )
            .then(() => {
              this.tagEditMode = false;
              this.tagEditIsSaving = false;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.details.tag = this.installationTag!.trim();

              this.$store.dispatch("snackbarInfo", {
                text: this.$t("installationDetails.updatedTag"),
              });
            })
            .catch((error) => {
              displayErrorMessage(error, {
                general: ["installationDetails.error.getUpdateTagError"],
              });
              this.tagEditIsSaving = false;
            });
        } else this.onCancelTagEdit();
      } else {
        this.$store.dispatch("snackbarError", {
          text: this.$t("installationDetails.tagRequired"),
        });
      }
    } else this.tagEditMode = true;
  }

  onCancelTagEdit() {
    setTimeout(() => {
      if (!this.tagEditIsSaving) {
        this.tagEditMode = false;
        this.installationTag = this.details.tag;
      }
    }, 200);
  }

  blockResponseToTopologyItem(block, name) {
    const res = {
      serialNumber: `b_${block.num}`,
      name: name,
      children: [
        {
          serialNumber: `bi_${block.num}_p`,
          name: `${this.$t("installationDetails.panels")}`,
          total: 0,
          children: [],
        },
        {
          serialNumber: `bi_${block.num}_e`,
          name: `${this.$t("installationDetails.ediboxes")}`,
          total: 0,
          children: [],
        },
        {
          serialNumber: `bi_${block.num}_u`,
          name: `${this.$t("installationDetails.units")}`,
          total: 0,
          children: [],
        },
        {
          serialNumber: `bi_${block.num}_g`,
          name: `${this.$t("installationDetails.gu_title")}`,
          total: 0,
          children: [],
        },
        {
          serialNumber: `bi_${block.num}_s`,
          name: `${this.$t("installationDetails.subblock_title")}`,
          children: [
            {
              serialNumber: `bi_${block.num}_p`,
              name: `${this.$t("installationDetails.panels")}`,
              total: 0,
              children: [],
            },
            {
              serialNumber: `bi_${block.num}_u`,
              name: `${this.$t("installationDetails.units")}`,
              children: [],
            },
          ],
        },
      ],
    };
    const storageDeviceType = (device) => {
      if (
        (!device.status &&
          device.subtype != null &&
          !device.subtype.toUpperCase().startsWith("NOWIFI") &&
          (!device.family.toUpperCase().startsWith("PANEL") ||
            !device.family.toUpperCase().startsWith("EDIBOX"))) ||
        !device.deployed ||
        device.family === PHONE_FAMILY ||
        device.family === GUARD_FAMILY
      )
        return false;

      const { family, type, subtype, versionHW } = device;

      let deviceType = this.installationMap.find(
        (i) =>
          i.family === family &&
          i.type === type &&
          i.subtype === subtype &&
          i.version === versionHW
      );

      if (!deviceType) {
        deviceType = {
          family,
          type,
          subtype,
          version: versionHW,
          devices: [],
        };
        this.installationMap.push(deviceType);
      }

      if (
        !deviceType.devices.find((d) => d.serialNumber === device.serialNumber)
      )
        deviceType.devices.push(device);
    };

    res.children[0].children = block.panels
      ?.sort((a, b) => a.num - b.num)
      .map((panel) => {
        storageDeviceType(panel);

        return {
          id: panel.serialNumber,
          customName: `${this.$t("installationDetails.panel")} ${
            panel.unitNumber
          }`,
          ...panel,
        };
      });

    res.children[1].children = block.ediboxes
      ?.sort((a, b) => a.num - b.num)
      .map((edibox) => {
        storageDeviceType(edibox);

        return {
          id: edibox.serialNumber,
          customName: `${this.$t("installationDetails.edibox")} ${
            edibox.unitNumber
          }`,
          ...edibox,
        };
      });

    res.children[2].children = block.units
      ?.sort((a, b) => a.num - b.num)
      .map((unit) => ({
        serialNumber: `bi_${block.num}_u${unit.num}`,
        name: `${this.$t("installationDetails.unit")} ${unit.num}`,
        total: unit.devices.length,
        children: unit.devices
          .sort((a, b) => a.num - b.num)
          .map((device) => {
            storageDeviceType(device);

            let deviceName =
              this.$t(`installationDetails.${device.family.toLowerCase()}`) +
              ` ${device.unitNumber}`;

            if (
              device.family === FAMILY.MONITOR ||
              device.family === FAMILY.PHONE
            )
              deviceName += ` ${device.subtype}`;

            return {
              id: device.serialNumber,
              customName: deviceName,
              ...device,
            };
          }),
      }));

    res.children[3].children = block.gu
      ?.sort((a, b) => a.num - b.num)
      .map((guard) => {
        storageDeviceType(guard);
        return {
          id: guard.serialNumber,
          name: `${this.$t("installationDetails.gu")} ${guard.unitNumber}`,
          customName: `${this.$t("installationDetails.gu")} ${
            guard.unitNumber
          }`,
          ...guard,
        };
      });

    if (block.subblocks?.length > 0) {
      this.panelSubblock = block.subblocks
        ?.sort((a, b) => a.num - b.num)
        .map((panelSubblock) => ({
          serialNumber: `bi_${block.num}_u${panelSubblock.num}`,
          name: `${this.$t("installationDetails.subblocks")} ${
            panelSubblock.num
          }`,
          total: panelSubblock.panels.length,
          children: panelSubblock.panels
            .sort((a, b) => a.num - b.num)
            .map((panel) => {
              storageDeviceType(panel);
              let deviceName =
                this.$t(`installationDetails.${panel.family.toLowerCase()}`) +
                ` ${panel.unitNumber}`;

              if (
                panel.family === FAMILY.MONITOR ||
                panel.family === FAMILY.PHONE
              )
                deviceName += ` ${panel.subtype}`;

              return {
                id: panel.serialNumber,
                customName: deviceName,
                ...panel,
              };
            }),
        }));
    } else {
      this.panelSubblock = [];
    }

    let monitorsList: any[] = [];
    if (block.subblocks?.length > 0) {
      this.monitorSubblock = block.subblocks
        ?.sort((a, b) => a.num - b.num)
        .map((monitorSubblock) => ({
          serialNumber: `bi_${block.num}_i${monitorSubblock.num}`,
          name: `${this.$t("installationDetails.subblocks")} ${
            monitorSubblock.num
          }`,
          total: monitorSubblock.length,
          children: monitorSubblock.units
            .sort((a, b) => a.num - b.num)
            .map((unit) => ({
              serialNumber: `bi_${block.num}_u${unit.num}`,
              name: `${this.$t("installationDetails.unit")} ${unit.num}`,
              total: unit.devices.length,
              children: unit.devices
                .sort((a, b) => a.num - b.num)
                .map((device) => {
                  storageDeviceType(device);

                  let deviceName =
                    this.$t(
                      `installationDetails.${device.family.toLowerCase()}`
                    ) + ` ${device.unitNumber}`;

                  if (
                    device.family === FAMILY.MONITOR ||
                    device.family === FAMILY.PHONE
                  )
                    deviceName += ` ${device.subtype}`;

                  return {
                    id: device.serialNumber,
                    customName: deviceName,
                    ...device,
                  };
                }),
            })),
        }));
      monitorsList.push(...this.monitorSubblock);
    } else {
      monitorsList = [];
    }

    // if (block.subblocks.length > 0) {
    //   this.guardSubblock = block.subblocks
    //     .sort((a, b) => a.num - b.num)
    //     .map(guardSubblock => ({
    //       serialNumber: `bi_${block.num}_u${guardSubblock.num}`,
    //       name: `${this.$t("installationDetails.subblocks")} ${
    //         guardSubblock.num
    //       }`,
    //       total: guardSubblock.gu.length,
    //       children: guardSubblock.gu
    //         .sort((a, b) => a.num - b.num)
    //         .map(guard => {
    //           storageDeviceType(guard);
    //           let deviceName =
    //             this.$t(`installationDetails.${guard.family.toLowerCase()}`) +
    //             ` ${guard.unitNumber}`;

    //           if (
    //             guard.family === FAMILY.MONITOR ||
    //             guard.family === FAMILY.PHONE
    //           )
    //             deviceName += ` ${guard.subtype}`;

    //           return {
    //             id: guard.serialNumber,
    //             name: deviceName,
    //             ...guard
    //           };
    //         })
    //     }));
    // } else {
    //   this.guardSubblock = [];
    // }

    res.children[0].total = res.children[0].children?.length;
    res.children[1].total = res.children[1].children?.length;
    res.children[2].total = res.children[2].children?.length;
    res.children[3].total = res.children[3].children?.length;
    res.children[4].children[0].children = [...this.panelSubblock] as any;
    res.children[4].children[1].children = [...monitorsList] as any;
    // res.children[3].children[2].children = [...this.guardSubblock] as any;

    return res;
  }

  filterTree(tree, filter) {
    if (!Array.isArray(tree)) return null;
    return JSON.parse(JSON.stringify(tree)).filter(function matchName(o) {
      let temp;

      if (
        !o.serialNumber ||
        !filter ||
        o.serialNumber.toUpperCase().indexOf(filter.trim().toUpperCase()) > -1
      ) {
        return true;
      }

      if (!Array.isArray(o.children)) {
        return false;
      }

      temp = o.children.filter(matchName);
      if (temp.length) {
        o.children = temp;
        return true;
      }
    });
  }

  clearTopologyPreview() {
    if (
      this.topologySelectedItems.length > 0 &&
      this.topologySelectedItems[0]
        .toUpperCase()
        .indexOf(this.topologySearch.trim().toUpperCase()) === -1
    )
      this.topologySelectedItems = [];
  }

  expandOrCollapse(expand) {
    this.expandOrCollapseOperationActive = true;

    this.$refs.topology.updateAll(expand);
  }

  forgetInstallation() {
    this.confirmForgetDialogShow = false;

    installationService
      .deleteInstallation(this.installationId)
      .then(() => {
        goTo("installations");
      })
      .catch((error) =>
        displayErrorMessage(error, {
          general: ["installation.error.getError"],
        })
      );
  }

  openFullScreenMarkerMap() {
    this.fullScreenMarkerMap = true;
  }

  /* async deleteElements() {
    let panelsList = this.filterPanels();
    let devicesToDelete = this.selectedDevices;

    if (devicesToDelete.length > 0) {
      panelsList = panelsList.filter(panel => {
        return !devicesToDelete.includes(panel.serialNumber);
      });
      await panelsList.map(async panel => {
        try {
          await deviceService
            .deleteDevicesFromPanel(panel.serialNumber, devicesToDelete)
        }catch(error){
            displayErrorMessage(error, {
              general: ["general.error.refreshError"]
            })
        }
      });
      await deviceService.deleteDevicesIds(devicesToDelete);
      this.refreshView();
    }

    this.selectedDevices = [];
  }
 */
  refreshInstallationPanels() {
    this.isRefreshingInstallation = true;

    this.filterPanels().forEach(async (panel) => {
      deviceService
        .refreshInstallation(panel.serialNumber)
        .then(() => {
          this.$store.dispatch("snackbarInfo", {
            active: true,
            text: this.$t("installationDetails.refreshedInfo"),
          });
          this.setShowNotifyRefresh(true);
        })
        .catch((error) =>
          displayErrorMessage(error, {
            general: ["general.error.refreshError"],
          })
        )
        .finally(() => {
          this.isRefreshingInstallation = false;
          this.showConfirmRefreshInstallation = false;
        });
    });
    this.$store.dispatch("snackbarInfo", {
      active: true,
      text: this.$t("installationDetails.disconnected"),
    });
    this.showConfirmRefreshInstallation = false;
  }

  filterPanels() {
    return this.installationMap
      .filter((familyGroup) => {
        return familyGroup.family === "PANEL";
      })
      .map((familyGroup) => {
        return familyGroup.devices;
      })
      .flat();
  }

  async openEventHistoryDevice(item: any) {
    this.deviceId = item.id;
    this.isLoadingEvents = true;
    this.pageNumberEvent = 0;
    this.eventsHistory = [];
    this.showEventHistory = true;
    await this.loadEvents();
    this.isLoadingEvents = false;
  }

  async openEventHistory() {
    this.isLoadingEvents = true;
    this.pageNumberEvent = 0;
    this.eventsHistory = [];
    this.showEventHistory = true;
    await this.loadEvents();
    this.isLoadingEvents = false;
  }

  loadMoreEvents(page) {
    this.pageNumberEvent = page;
    this.loadEvents();
  }

  loadEvents() {
    return rexistroService
      .getEventHistory(
        "deviceId=" +
          this.deviceId +
          "&startDateHistory=" +
          this.startDateHistory +
          "&endDateHistory=" +
          this.endDateHistory,
        this.pageNumberEvent
      )
      .then((res) => {
        this.totalPagesEvent = res.data.totalPages;
        this.totalSizeEvent = res.data.totalElements;
        this.eventsHistory.push(...res.data.content);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.errorGetInfo"],
        });
        throw err;
      });
  }

  get isOpenditRole() {
    return (
      this.$store.getters["internalusers/roleUserData"]?.at(0) ===
      "ROLE_OPENDIT"
    );
  }
}
</script>
<style>
.details-title {
  max-width: calc(100% - 325px);
}
.details-title.mobile {
  max-width: calc(100% - 150px);
}
.topology-card-content {
  border-top: 1px solid #e0e0e0;
  padding: 0;
}
.topology-search {
  margin-top: 0px;
  padding-top: 0px;
  position: relative;
  top: 10px;
}
.topology-tree {
  overflow-y: auto;
  overflow-x: hidden;
  height: 465px;
  border-right: 1px solid #e0e0e0;
  padding: 16px 0 0 16px;
}
.topology-tree-empty {
  display: none;
  word-break: break-all;
}
.topology-tree:empty + .topology-tree-empty {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbb;
  font-weight: 400;
  font-size: 13px;
}
.topology-tree .v-treeview-node__content {
  position: relative;
  width: 100%;
}
.topology-tree .v-treeview-node__content .v-treeview-node__label {
  position: relative;
  width: calc(100% - 45px);
}
.topology-tree .v-treeview-node__content .v-treeview-node__label .v-tooltip {
  max-width: calc(100% - 165px);
  margin-right: 5px;
}
.topology-tree .item {
  color: #1976d2;
  font-size: 14px;
}
.topology-tree .list.empty {
  opacity: 0.4;
}
.topology-tree .v-treeview-node--active {
  background-color: #e6f0fb !important;
}
.topology-tree .v-treeview-node--active .item {
  font-weight: bold;
}

.no-topology {
  padding: 0 16px 20px;
  display: block;
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 460px) {
  .topology-tree .v-treeview-node__content .di {
    display: none;
  }

  .topology-tree .v-treeview-node__content .v-treeview-node__label .v-tooltip {
    max-width: calc(100% - 120px);
  }
}

.installation-map {
  height: calc(100% - 61px);
  min-height: 120px;
}
.map-button-full-screen {
  margin: -15px 0px -15px 0px;
  color: rgb(0, 0, 0, 0.54) !important;
}

/* CSS dx-datagrid   */

.dx-datagrid .dx-header-row {
  /* Estilos para la fila de encabezado */
  color: #00000099 !important;
  font: 10.05px Roboto, sans-serif !important;
  padding: 0px 16px;
  font-weight: bold !important;
}

.dx-datagrid .dx-row {
  /* Estilos para las filas de datos */
  color: #000000de;
  font: 12.25px Roboto, sans-serif;
  padding: 0px 16px;
}

.dx-datagrid-header-panel {
  border-bottom: 0px solid #ddd;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  text-align: left !important;
}
</style>
