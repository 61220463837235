import { translateText as t } from "@/lang/i18n";
import ExportBase from "./ExportBase";
export default class ExportPlans<T> extends ExportBase<T> {
  constructor() {
    super(t("menu.plans"));
    this.generateHeaders();
  }

  private generateHeaders(): void {
    this.exportTable.addHeader({
      key: "code",
      label: t("plans.code"),
    });
    this.exportTable.addHeader({
      key: "name",
      label: t("plans.name"),
    });
    this.exportTable.addHeader({
      key: "services",
      label: t("plans.services"),
    });
    this.exportTable.addHeader({
      key: "plan.renewalPeriod",
      label: t("plans.renovation"),
      display: (value: string) => {
        return t("plans.renewalTypes." + (value || "NO_BILLING_PERIOD"));
      },
    });
    this.exportTable.addHeader({
      key: "isTrial",
      label: t("plans.trialAvailable"),
      display: this.displayYesNo.bind(this),
    });
  }
}
