import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FrmxDialog from "@/ui/components/FrmxDialog/FrmxDialog.vue";
import FrmxInputText from "@/ui/components/FrmxInputText/FrmxInputText.vue";
import FrmxButton from "@/ui/components/FrmxButton/FrmxButton.vue";
import SubscriptionNoWifiController from "@/domain/subscriptionNoWifi/SubscriptionNoWifiController";
import { AxiosError } from "axios";

@Component({
  components: { FrmxDialog, FrmxInputText, FrmxButton },
})
export default class ExtendTrialDialog extends Vue {
  @Prop({ default: false }) public value!: boolean;
  @Prop({ default: "" }) public logicalId!: string;
  @Prop({ default: "" }) public expiredDate!: string;

  public visibleDialog = false;
  public visibleDialogConfirm = false;
  public visibleDialogOk = false;
  public visibleDialogko = false;
  public errorMessage = "";
  public loading = false;

  /** Valor por defecto de 30 días. */
  public picker = "";
  /** Valor mínimo los 30 días que hay por defecto. */
  public minPicker = this.picker;
  /** Valor máximo un año desde la fecha actual. */
  public maxPicker = this.get365Days();

  @Watch("value")
  private onValueChange(value: boolean) {
    this.reasonValue = "";
    this.visibleDialog = value;
  }

  @Watch("expiredDate")
  private onExpiredDateChange(value: string) {
    this.picker = value;
    this.minPicker = this.picker;
  }

  @Watch("visibleDialog")
  private onVisibleDialogChange(value: boolean) {
    this.$emit("input", value);
  }

  mounted() {
    this.visibleDialog = this.value;
    this.picker = this.expiredDate;
    this.minPicker = this.picker;
  }

  public onClickClose() {
    this.close();
  }

  public onClickSave() {
    this.onVisibleDialogChange(false);
    this.visibleDialogConfirm = true;
  }

  public onClickCancel() {
    this.close();
  }

  public onClickConfirm() {
    this.loading = true;
    this.validateReason();
    if (this.isFormOk()) {
      this.extendTrial();
    } else {
      this.loading = false;
    }
  }

  private extendTrial() {
    this.loading = true;
    const ctrl = new SubscriptionNoWifiController();
    ctrl
      .extendTrial(this.logicalId, this.picker, this.reasonValue)
      .then(() => {
        this.visibleDialogConfirm = false;
        this.visibleDialogOk = true;
        this.$emit("trialExtended");
      })
      .catch(this.activeTrialHandleError)
      .finally(() => {
        this.loading = false;
      });
  }

  private isFormOk() {
    return this.reasonErrors.length === 0;
  }

  private close() {
    this.onVisibleDialogChange(false);
    this.visibleDialogConfirm = false;
    this.visibleDialogOk = false;
    this.visibleDialogko = false;
  }

  /** Manejar errores al añadir un invitado. */
  private activeTrialHandleError(
    error: AxiosError<{
      title: string;
      status: number;
      detail: string;
    }>
  ) {
    this.errorMessage = "";
    if (error.response?.data.status === 500) {
      this.errorMessage = "Internal server error. Please try again later.";
    } else {
      this.errorMessage = error.response?.data.detail ?? "";
    }
    this.visibleDialogConfirm = false;
    this.visibleDialogko = true;
  }

  /**
   * Obtener la fecha actual más 365 días.
   * En formato YYYY-MM-DD.
   */
  private get365Days(): string {
    return new Date(new Date().setDate(new Date().getDate() + 365))
      .toISOString()
      .split("T")[0];
  }

  // #region Motivo de la invitación.
  public reasonValue = "";
  public reasonErrors: Array<string> = [];

  public onChangeReason() {
    this.validateReason();
  }

  public validateReason() {
    if (!this.reasonValue) {
      this.reasonErrors = [this.$t("general.inputErrors.required").toString()];
    } else {
      this.reasonErrors = [];
    }
  }
  // #endregion
}
