/* eslint-disable @typescript-eslint/no-explicit-any */
import ExportCell from "./ExportCell";

export default interface ExportHeaderInterface<T> {
  /** Nombre del campo y clave de la columna */
  key: string;
  /** Etiqueta de la columna */
  label: string;
  /** Ancho de la columna */
  width?: number;
  /** Función que genera el texto de la celda. */
  display?: (value: string, item?: T) => string;
  /** Función que genera los estilos de la celda. */
  styles?: (value: string, cell: ExportCell, item?: T) => void;
}
/** Tipo de exportación, puede ser "excel" o "pdf" o "csv" */
export enum ExportType {
  EXCEL = "excel",
  PDF = "pdf",
  CSV = "csv",
}

export enum HorizontalAlign {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  JUSTIFY = "justify",
}

export interface ExportEvent {
  type: ExportType;
  sort?: { field: string; order: number };
}
