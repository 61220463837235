import { jsPDF, jsPDFOptions } from "jspdf";
import autoTable, { Cell, CellHookData } from "jspdf-autotable";

export default class ExportTablePDF {
  private title: string;
  private jspdf: jsPDF;
  private headers: string[] = [];
  private data: string[][] = [];
  private callbackStyles: (
    indexRow: number,
    indexCol: number,
    cell: Cell
  ) => void;
  constructor(
    title: string,
    callbackStyles: (indexRow: number, indexCol: number, cell: Cell) => void
  ) {
    this.title = title;
    const options: jsPDFOptions = {
      orientation: "landscape",
    };
    this.jspdf = new jsPDF(options);
    this.callbackStyles = callbackStyles;
  }

  public addHeader(header: string): void {
    this.headers.push(header);
  }

  public addDatum(data: string[]): void {
    this.data.push(data);
  }

  private generateHeaderTitle(): void {
    // Ponerse en la primera página.
    this.jspdf.setPage(1);
    // Cabecear.
    const headerText = this.title;
    this.jspdf.setFontSize(15);
    this.jspdf.text(headerText, 43, 20);
    // Logo.
    const imageUrl = process.env.VUE_APP_PUBLIC_PATH + "assets/blue.png";
    this.jspdf.addImage(imageUrl, "PNG", 10, 10.5, 33, 17);
  }

  public download(fileName: string): void {
    this.generateHeaderTitle();
    autoTable(this.jspdf, {
      startY: 25,
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      theme: "grid",
      head: [this.headers],
      body: this.data,
      didParseCell: this.didParseCell.bind(this),
      headStyles: {
        valign: "middle",
      },
      bodyStyles: {
        valign: "middle",
        fontSize: 6,
      },
      styles: {
        lineColor: "#808080",
        lineWidth: 0.2,
      },
    });
    this.jspdf.save(fileName + ".pdf");
  }

  private didParseCell(data: CellHookData): void {
    if (data.section === "head") {
      data.cell.styles.fillColor = "#0070C0";
      data.cell.styles.textColor = "#FFFFFF";
    } else if (data.section === "body") {
      this.callbackStyles(data.row.index, data.column.index, data.cell);
    }
  }
}
